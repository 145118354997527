import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import {
	Checkbox,
	Container,
	FormControl,
	Grid,
	IconButton,
	MenuItem,
	Select,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faX } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import { ExamplesTable } from './components/ExamplesTable';
import { CustomPagination } from '../../../../Components/Shared/CustomPagination';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import { CommentsModal } from './components/CommentsModal';
import { EditElementModal } from './components/EditElementModal';
import { DeleteExampleModal } from './components/DeleteExampleModal';
import { CreateOrUpdateExampleModal } from './components/CreateOrUpdateExampleModal';
import { EditViewModal } from './components/EditViewModal';
import { QueryBuilderModal } from './components/QueryBuilderModal';

// Styles
import useStyles from './styles';

// Redux
import {
	UPDATE_QUERY,
	UPDATE_SAVED_QUERIES,
} from '../../../../redux/queries.slice';
import {
	GET_EXAMPLES,
	GET_EXAMPLE_FILE,
	SET_CURRENT_EXAMPLE,
	SET_EXAMPLE_DOCUMENTS_BUFFER,
	SET_EXAMPLE_IMAGES_BUFFER,
} from '../../../../redux/examples.slice';

// Services
import { getColumns, getRows } from './examples.services';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { colors } from '../../../../consts/colors';

export function TaskExamples() {
	const classes = useStyles();

	const dispatch = useDispatch();

	const {
		examples: examplesState,
		imagesBuffer: imagesBufferState,
		documentsBuffer: documentsBufferState,
	} = useSelector((state) => state.examples);
	const { totalExamples: totalExamplesState } = useSelector(
		(state) => state.examples
	);
	const { categories: categoriesState, schema: schemaState } = useSelector(
		(state) => state.schema
	);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [rowsPerPageOptions] = useState([25, 50, 100]);
	const [currentRowId, setCurrentRowId] = useState('');
	const [selectedRows, setSelectedRows] = useState([]);
	const [currentCellId, setCurrentCellId] = useState('');
	const [currentColumns, setCurrentColumns] = useState([]);
	const [allColumns, setAllColumns] = useState([]);
	const [rows, setRows] = useState([]);
	const [orderedBy, setOrderedBy] = useState([null, null]);

	// todo: when multiple query suported
	// const [query, setQuery] = useState([]);
	const [query, setQuery] = useState({ field: '', operator: '==', value: '' });
	const [filters, setFilters] = useState({
		only_with_comments: false,
		labeling_status: [],
		query: '',
		order: '',
		order_by: '',
	});
	const [openStatusTooltip, setOpenStatusTooltip] = useState(false);

	// Modals
	const [openDeleteExampleModal, setOpenDeleteExampleModal] = useState(false);
	const [openCreateExampleModal, setOpenCreateExampleModal] = useState(false);
	const [openEditExampleModal, setOpenEditExampleModal] = useState(false);
	const [openEditElementModal, setOpenEditElementModal] = useState(false);
	const [openEditViewModal, setOpenEditViewModal] = useState(false);
	const [openQueryBuilderModal, setOpenQueryBuilderModal] = useState(false);
	const [openCommentsModal, setOpenCommentsModal] = useState(false);

	const handleOpenStatusTooltip = () => {
		setOpenStatusTooltip(!openStatusTooltip);
	};

	const getExamplesWithFilters = async () => {
		await dispatch(
			GET_EXAMPLES({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				page: page + 1,
				per_page: rowsPerPage,
				labeling_status:
					filters.labeling_status.length > 0
						? filters['labeling_status'].join('|')
						: '',
				only_with_comments: filters.only_with_comments,
				query: filters.query,
				order: filters.order,
				order_by: filters.order_by,
			})
		);
	};

	useEffect(() => {
		if (currentTaskState.uuid && accessToken) getExamplesWithFilters();
	}, [filters]);

	const handleChangeFilters = (e) => {
		const { id, name, value, checked } = e.target;
		if (value === 'on') {
			if (id === 'only_with_comments')
				setFilters({ ...filters, [name]: checked });
			if (id === 'labeling_status') {
				setFilters({
					...filters,
					labeling_status: filters.labeling_status.includes(name)
						? filters.labeling_status.filter((status) => status !== name)
						: [...filters.labeling_status, name],
				});
			}
		}
	};

	const setImagesOnBuffer = async () => {
		const imageElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'image_file') {
						examplesState.forEach((example) => {
							example.inputs.forEach((exampleInput) => {
								if (exampleInput.element === input.name)
									if (exampleInput.value !== '') {
										const checkIfInBuffer = imagesBufferState.find(
											(image) => image.elementId === exampleInput.value
										);
										if (!checkIfInBuffer) imageElements.push(exampleInput);
									}
							});
						});
					}
				});
			}

			if (schemaState.metadata && schemaState.metadata.length > 0) {
				schemaState.metadata.forEach((meta) => {
					if (meta.type === 'image_file') {
						examplesState.forEach((example) => {
							example.metadata.forEach((exampleMetadata) => {
								if (exampleMetadata.element === meta.name)
									if (exampleMetadata.value !== '') {
										const checkIfInBuffer = imagesBufferState.find(
											(image) => image.elementId === exampleMetadata.value
										);
										if (!checkIfInBuffer) imageElements.push(exampleMetadata);
									}
							});
						});
					}
				});
			}

			if (schemaState.outputs && schemaState.outputs.length > 0) {
				schemaState.outputs.forEach((meta) => {
					if (meta.type === 'image_file') {
						examplesState.forEach((example) => {
							example.outputs.forEach((exampleOutput) => {
								if (exampleOutput.element === meta.name)
									if (exampleOutput.value !== '') {
										const checkIfInBuffer = imagesBufferState.find(
											(image) => image.elementId === exampleOutput.value
										);
										if (!checkIfInBuffer) imageElements.push(exampleOutput);
									}
							});
						});
					}
				});
			}
		}

		if (imageElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					imageElements.map(async (element) => {
						const resThumbnail = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
								thumbnail: true,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							// image: resThumbnail.payload.download_url,
							thumbnail: resThumbnail.payload.download_url,
						};
					})
				).then((result) => result)),
			];

			dispatch(SET_EXAMPLE_IMAGES_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_IMAGES_BUFFER());
	};

	const setDocumentsOnBuffer = async () => {
		const documentElements = [];
		if (schemaState && currentTaskState.id) {
			if (schemaState.inputs && schemaState.inputs.length > 0) {
				schemaState.inputs.forEach((input) => {
					if (input.type === 'document_file') {
						examplesState.forEach((example) => {
							example.inputs.forEach((exampleInput) => {
								if (exampleInput.element === input.name)
									if (exampleInput.value !== '')
										documentElements.push(exampleInput);
							});
						});
					}
				});
			}

			if (schemaState.metadata && schemaState.metadata.length > 0) {
				schemaState.metadata.forEach((meta) => {
					if (meta.type === 'document_file') {
						examplesState.forEach((example) => {
							example.metadata.forEach((exampleMetadata) => {
								if (exampleMetadata.element === meta.name)
									if (exampleMetadata.value !== '')
										documentElements.push(exampleMetadata);
							});
						});
					}
				});
			}

			if (schemaState.outputs && schemaState.outputs.length > 0) {
				schemaState.outputs.forEach((meta) => {
					if (meta.type === 'document_file') {
						examplesState.forEach((example) => {
							example.outputs.forEach((exampleOutput) => {
								if (exampleOutput.element === meta.name)
									if (exampleOutput.value !== '')
										documentElements.push(exampleOutput);
							});
						});
					}
				});
			}
		}

		if (documentElements.length > 0) {
			let resAll = [];

			resAll = [
				...(await Promise.all(
					documentElements.map(async (element) => {
						const resDocument = await dispatch(
							GET_EXAMPLE_FILE({
								taskId: currentTaskState.id,
								fileId: element.value,
								accessToken,
								dispatch,
							})
						);

						return {
							elementId: element.value,
							elementName: element.element,
							url: resDocument.payload.download_url,
						};
					})
				).then((result) => result)),
			];
			dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER(resAll));
		} else dispatch(SET_EXAMPLE_DOCUMENTS_BUFFER());
	};

	useEffect(() => {
		setAllColumns([]);
		setCurrentColumns([]);
		setOpenEditViewModal(false);
		dispatch(SET_CURRENT_EXAMPLE({}));
		if (
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`).length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) =>
					localStorage
						.getItem(`${currentTaskState.uuid}-exampleColumns`)
						.includes(col.field)
				)
			);
		}
	}, []);

	useEffect(() => {
		setImagesOnBuffer();
		setDocumentsOnBuffer();
	}, [examplesState]);

	useEffect(() => {
		if (currentCellId !== '') setOpenEditElementModal(true);
		else setOpenEditElementModal(false);
	}, [currentCellId]);

	useEffect(() => {
		if (currentRowId !== '') {
			dispatch(
				SET_CURRENT_EXAMPLE(
					examplesState.find((example) => example.id === currentRowId)
				)
			);
		}
	}, [currentRowId]);

	useEffect(() => {
		if (!openEditElementModal) {
			setCurrentRowId('');
			setCurrentCellId('');
			dispatch(SET_CURRENT_EXAMPLE({}));
		}
	}, [openEditElementModal]);

	useEffect(() => {
		// todo
		const getCategories = async () => {};
		if (
			!imagesBufferState.imagesBufferIsLoading &&
			accessToken &&
			currentTaskState.uuid
		) {
			getColumns({
				setAllColumns,
				classes,
				schemaState,
				examplesState,
				categoriesState,
				currentTaskState,
				accessToken,
				setOpenEditExampleModal,
				setCurrentRowId,
				dispatch,
				imagesBufferState,
				documentsBufferState,
				HasAccess,
				openStatusTooltip,
				handleOpenStatusTooltip,
				setOpenCommentsModal,
			});
			getRows({
				setRows,
				schemaState,
				examplesState,
				currentTaskState,
				accessToken,
				dispatch,
			});
		}
	}, [schemaState, examplesState, imagesBufferState, documentsBufferState]);

	useEffect(() => {
		setCurrentColumns(allColumns);

		if (
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`) &&
			localStorage.getItem(`${currentTaskState.uuid}-exampleColumns`).length > 0
		) {
			setCurrentColumns(
				allColumns.filter((col) => {
					const tmp = localStorage.getItem(
						`${currentTaskState.uuid}-exampleColumns`
					);
					if (tmp.includes(col.field)) return col;
					return false;
				})
			);
		} else {
			setCurrentColumns(allColumns);
		}
	}, [allColumns]);

	const openCreateExampleView = () => {
		setOpenCreateExampleModal(true);
	};

	const openEditExampleView = () => {
		setOpenEditViewModal(true);
	};

	const openQueryBuilder = () => {
		setOpenQueryBuilderModal(true);
	};

	useEffect(() => {
		if (accessToken && currentTaskState.uuid) {
			getExamplesWithFilters();
		}
	}, [page, rowsPerPage]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
	};

	useEffect(() => {
		if (!openCommentsModal) {
			setCurrentRowId('');
			setSelectedRows([]);
			dispatch(SET_CURRENT_EXAMPLE({}));
		}
	}, [openCommentsModal]);

	const handleChangeOrderBy = (e) => {
		const { value } = e.target;
		const tmpValues = value.split(',');
		setOrderedBy(value);
		setFilters({ ...filters, order_by: tmpValues[0], order: tmpValues[1] });
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			{/* <QueryBuilderComponent
				updateQuery={updateQuery}
				openCreateExampleView={openCreateExampleView}
				setOpenCreateExampleModal={setOpenCreateExampleModal}
			/> */}
			<Container maxWidth={false} sx={{ padding: '0px !important' }}>
				<Grid
					container
					gap={1}
					sx={{ alignItems: 'center', fontSize: 'small', marginBottom: '24px' }}
				>
					<Grid
						item
						xs={12}
						md={5.94}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							outline: '2px solid #d3eafa',
							borderRadius: '12px',
							padding: '12px',
							marginBottom: '12px',
						}}
					>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									alignItems: 'center',
									fontWeight: 'bold !important',
								}}
							>
								By comments:
							</Grid>
							<Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
								<FormControl>
									<Checkbox
										id="only_with_comments"
										color="primary"
										name="only_with_comments"
										checked={filters.only_with_comments}
										onChange={handleChangeFilters}
									/>
								</FormControl>
								Only examples with comments
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								alignItems: 'center',
								fontWeight: 'bold !important',
							}}
						>
							By status label:
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
							<FormControl>
								<Checkbox
									id="labeling_status"
									color="primary"
									name="labeled"
									checked={filters.labeling_status.includes('labeled')}
									onChange={handleChangeFilters}
								/>
							</FormControl>
							Labeled
							<FormControl>
								<Checkbox
									id="labeling_status"
									color="primary"
									name="unlabeled"
									checked={filters.labeling_status.includes('unlabeled')}
									onChange={handleChangeFilters}
								/>
							</FormControl>
							Unlabeled
							<FormControl>
								<Checkbox
									id="labeling_status"
									color="primary"
									name="pending_review"
									checked={filters.labeling_status.includes('pending_review')}
									onChange={handleChangeFilters}
								/>
							</FormControl>
							Pending review
							<FormControl>
								<Checkbox
									id="labeling_status"
									color="primary"
									name="rejected"
									checked={filters.labeling_status.includes('rejected')}
									onChange={handleChangeFilters}
								/>
							</FormControl>
							Rejected
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={5.94}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							outline: '2px solid #d3eafa',
							borderRadius: '12px',
							padding: '12px',
							marginBottom: '12px',
						}}
					>
						<Grid container spacing={1}>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontWeight: 'bold !important',
									}}
								>
									Edit columns view:
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontWeight: 'bold !important',
									}}
								>
									Query builder:
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										fontWeight: 'bold !important',
									}}
								>
									Order by:
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<StandardButton
										value="Open editor"
										type="textButton"
										handleClick={openEditExampleView}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<StandardButton
										value="Open builder"
										type="textButton"
										handleClick={openQueryBuilder}
									/>
								</Grid>
								<Grid
									item
									xs={4}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<FormControl fullWidth sx={{}}>
										<Select
											id="orderBy"
											name="orderBy"
											value={orderedBy}
											onChange={handleChangeOrderBy}
										>
											<MenuItem value="labeling_status,asc">
												Labeling status: ascendent
											</MenuItem>
											<MenuItem value="labeling_status,desc">
												Labeling status: descendent
											</MenuItem>
											<MenuItem value="created_at,asc">
												Created at: from oldest to newest
											</MenuItem>
											<MenuItem value="created_at,desc">
												Created at: from newest to oldest
											</MenuItem>
											<MenuItem value="modified_at,asc">
												Modified at: from oldest to newest
											</MenuItem>
											<MenuItem value="modified_at,desc">
												Modified at: from newest to oldest
											</MenuItem>
											<MenuItem value="activity_at,asc">
												Activity at: from oldest to newest
											</MenuItem>
											<MenuItem value="activity_at,desc">
												Activity at: from newest to oldest
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						gap={1}
						sx={{
							outline: '2px solid #d3eafa',
							borderRadius: '6px',
							padding: '12px 12px',
						}}
					>
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								fontWeight: 'bold',
								alignItems: 'center',
							}}
						>
							Current filters:
						</Grid>
						{filters.labeling_status.length > 0 &&
							filters.labeling_status.map((label) => (
								<Grid
									item
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										outline: '2px solid #d3eafa',
										borderRadius: '12px',
										padding: '0px 6px',
										backgroundColor: '#d3eafa',
									}}
								>
									{label}
									<IconButton
										aria-label="delete"
										color="primary"
										onClick={() =>
											setFilters({
												...filters,
												labeling_status: filters.labeling_status.filter(
													(l) => l !== label
												),
											})
										}
									>
										<FontAwesomeIcon
											icon={faX}
											style={{
												fontSize: '12px',
											}}
										/>
									</IconButton>
								</Grid>
							))}
						{filters.only_with_comments && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								with comments
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() =>
										setFilters({ ...filters, only_with_comments: false })
									}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order_by !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								order by: {filters.order_by}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order_by: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.order !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								order: {filters.order}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => setFilters({ ...filters, order: '' })}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
						{filters.query !== '' && (
							<Grid
								item
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									outline: '2px solid #d3eafa',
									borderRadius: '12px',
									padding: '0px 6px',
									backgroundColor: '#d3eafa',
								}}
							>
								{`${filters.query.split('=')[0]} = ${
									filters.query.split('=')[1]
								}`}
								<IconButton
									aria-label="delete"
									color="primary"
									onClick={() => {
										setFilters({ ...filters, query: '' });
										setQuery({ field: '', operator: '==', value: '' });
									}}
								>
									<FontAwesomeIcon
										icon={faX}
										style={{
											fontSize: '12px',
										}}
									/>
								</IconButton>
							</Grid>
						)}
					</Grid>
					<Grid item xs={12}>
						<div className={classes.examplesFound}>
							<div className={classes.examplesFoundTitle}>Examples:</div>
							<div className={classes.examplesFoundCount}>
								{totalExamplesState} examples found
							</div>
						</div>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							gap: 1,
							justifyContent: { xs: 'flex-start' },
						}}
					>
						<HasAccess
							roles={defaultRoles}
							permissions="task.create"
							renderAuthFailed={
								<StandardButton
									type="disabled"
									value="Create example"
									icon={<AddIcon />}
								/>
							}
						>
							<StandardButton
								value="Create example"
								handleClick={openCreateExampleView}
								icon={<AddIcon />}
							/>
						</HasAccess>
						<HasAccess
							roles={defaultRoles}
							permissions="task.update"
							renderAuthFailed={
								<StandardButton
									type="disabled"
									value="Update example"
									icon={<EditIcon />}
								/>
							}
						>
							{currentRowId === '' ? (
								<StandardButton
									type="disabled"
									value="Update example"
									icon={<EditIcon />}
								/>
							) : (
								<StandardButton
									value="Update example"
									handleClick={() => setOpenEditExampleModal(true)}
									icon={<EditIcon />}
								/>
							)}
						</HasAccess>
						<HasAccess
							roles={defaultRoles}
							permissions="task.delete"
							renderAuthFailed={
								<StandardButton
									type="disabled"
									value="Delete example"
									icon={<DeleteIcon />}
								/>
							}
						>
							{selectedRows.length === 0 ? (
								<StandardButton
									type="disabled"
									value="Delete example"
									icon={<DeleteIcon />}
								/>
							) : (
								<StandardButton
									value={
										selectedRows.length <= 1
											? 'Delete example'
											: 'Delete examples'
									}
									handleClick={() => setOpenDeleteExampleModal(true)}
									icon={<DeleteIcon />}
								/>
							)}
						</HasAccess>
					</Grid>
				</Grid>
				<Grid container>
					<Grid
						item
						xs={12}
						sm={2}
						sx={{
							display: 'flex',
							gap: 1,
							justifyContent: { xs: 'flex-start' },
							fontSize: '13px',
							fontWeight: 600,
							letterSpacing: '0.14px',
							color: '#1492E6',
						}}
					>
						Icons color legend:
					</Grid>
					<Grid
						item
						xs={12}
						sm={10}
						sx={{
							display: 'flex',
							gap: 4,
							color: '#545454',
							fontSize: '13px',
							fontWeight: 600,
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskInput,
									marginRigth: '12px',
								}}
							/>
							Inputs
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskMetaData,
									marginRigth: '12px',
								}}
							/>
							Metadata
						</div>
						<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
							<div
								style={{
									float: 'left',
									height: '10px',
									width: '10px',
									clear: 'both',
									backgroundColor: colors.taskOutput,
									marginRigth: '12px',
								}}
							/>
							Outputs
						</div>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<Box className={classes.tableContainer}>
							<ExamplesTable
								rows={rows}
								currentColumns={currentColumns}
								setCurrentCellId={setCurrentCellId}
								setCurrentRowId={setCurrentRowId}
								currentRowId={currentRowId}
								openEditExampleModal={openEditExampleModal}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								filters={filters}
								setFilters={setFilters}
							/>
						</Box>
					</Grid>
					<CustomPagination
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						total={totalExamplesState}
					/>
				</Grid>
			</Container>
			{openCreateExampleModal && (
				<CreateOrUpdateExampleModal
					open={openCreateExampleModal}
					setOpen={setOpenCreateExampleModal}
					type="create"
					allColumns={allColumns}
					schemaState={schemaState}
					setCurrentRowId={setCurrentRowId}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openEditExampleModal && (
				<CreateOrUpdateExampleModal
					open={openEditExampleModal}
					setOpen={setOpenEditExampleModal}
					type="update"
					step={1}
					allColumns={allColumns}
					currentRowId={currentRowId}
					setOpenDeleteExampleModal={setOpenDeleteExampleModal}
					setCurrentRowId={setCurrentRowId}
					setSelectedRows={setSelectedRows}
				/>
			)}
			{openEditElementModal && (
				<EditElementModal
					open={openEditElementModal}
					setOpen={setOpenEditElementModal}
					currentCellId={currentCellId}
					setCurrentCellId={setCurrentCellId}
					currentRowId={currentRowId}
					setCurrentRowId={setCurrentRowId}
					allColumns={allColumns}
				/>
			)}
			{openCommentsModal && (
				<CommentsModal
					open={openCommentsModal}
					setOpen={setOpenCommentsModal}
					currentRowId={currentRowId}
					currentTaskId={currentTaskState.uuid}
				/>
			)}
			{openDeleteExampleModal && (
				<DeleteExampleModal
					open={openDeleteExampleModal}
					setOpen={setOpenDeleteExampleModal}
					setOpenEditExampleModal={setOpenEditExampleModal}
					currentRowId={currentRowId}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					setCurrentRowId={setCurrentRowId}
				/>
			)}
			{openEditViewModal && (
				<EditViewModal
					open={openEditViewModal}
					setOpen={setOpenEditViewModal}
					allColumns={allColumns}
					currentColumns={currentColumns}
					setCurrentColumns={setCurrentColumns}
				/>
			)}
			{openQueryBuilderModal && (
				<QueryBuilderModal
					open={openQueryBuilderModal}
					setOpen={setOpenQueryBuilderModal}
					allColumns={allColumns}
					query={query}
					setQuery={setQuery}
					filters={filters}
					setFilters={setFilters}
				/>
			)}
		</HasAccess>
	);
}
