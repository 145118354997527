import PropTypes from 'prop-types';

// Components
import { Grid, CssBaseline } from '@mui/material';

// Styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useStyles from './styles';

// Consts
import { statuses } from '../../../consts/status';
import { CustomTooltip } from '../CustomTooltip';

const theme = createTheme();

function StatusBar({ status = null, statusCode = null }) {
	const classes = useStyles();

	if (statusCode)
		return (
			<ThemeProvider theme={theme}>
				<CustomTooltip title={statuses[statusCode].description}>
					<Grid
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
						}}
					>
						<CssBaseline />
						<div
							className={classes.statusContainer}
							style={{
								backgroundColor: statuses[statusCode]?.color,
							}}
						>
							{statuses[statusCode].name}
						</div>
					</Grid>
				</CustomTooltip>
			</ThemeProvider>
		);

	return (
		<ThemeProvider theme={theme}>
			<CustomTooltip title={status?.description}>
				<Grid
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					<CssBaseline />
					<div
						className={classes.statusContainer}
						style={{
							backgroundColor: statuses[status.status_code]?.color,
						}}
					>
						{status?.display_name}
					</div>
				</Grid>
			</CustomTooltip>
		</ThemeProvider>
	);
}

StatusBar.propTypes = {
	status: PropTypes.object,
	statusCode: PropTypes.string,
};

export default StatusBar;
