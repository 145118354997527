import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import {
	DataGridPro,
	GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-pro';
import { Loader } from '../../../../../../Components/Shared/Loader';

import { colors } from '../../../../../../consts/colors';

export const AIPredictionsTable = ({
	allColumns,
	rows,
	setCurrentCellId,
	setCurrentRowId,
	currentRowId,
	setSelectedRows,
	filters,
	setFilters,
	selectedRows,
}) => {
	const { schema: schemaState } = useSelector((state) => state.schema);
	const { isLoading: isLoadingAITestingState } = useSelector(
		(state) => state.predictions
	);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(100);
	const [sortModel, setSortModel] = useState();

	useEffect(() => {
		if (sortModel?.length > 0) {
			const { field, sort } = sortModel[0];
			setFilters({ ...filters, order: sort, order_by: field });
		}
	}, [sortModel]);

	if (isLoadingAITestingState) {
		return (
			<div
				style={{
					width: '100%',
					height: '500px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Loader size="L" local />;
			</div>
		);
	}

	return (
		allColumns &&
		allColumns.length > 0 && (
			<DataGridPro
				initialState={{
					pinnedColumns: {
						left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'airelease'],
					},
				}}
				rows={rows}
				columns={allColumns}
				sortingMode="server"
				sortModel={sortModel}
				onSortModelChange={(model) => setSortModel(model)}
				rowSelection
				rowSelectionModel={selectedRows}
				onRowSelectionModelChange={(selectionModel) => {
					if (selectionModel.length > 1 || selectionModel.length === 0) {
						setCurrentRowId('');
					} else {
						setCurrentRowId(selectionModel[0]);
					}
					setSelectedRows(selectionModel);
				}}
				// onRowClick={(row) => setCurrentRowId(row.id)}
				checkboxSelection
				disableSelectionOnClick
				hideFooterPagination
				// onCellDoubleClick={(params, event) => {
				// 	if (!event.ctrlKey) {
				// 		event.defaultMuiPrevented = true;
				// 	}
				// 	console.log(params);
				// 	if (
				// 		canUpdate &&
				// 		params.field !== 'edit' &&
				// 		params.field !== 'status'
				// 	) {
				// 		setCurrentCellId(params.field);
				// 		setCurrentRowId(params.row.id);
				// 	}
				// }}
				sx={{
					border: 'none',
					'.MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox': {
						outline: 'none',
						// width: '50px !important',
						minWidth: '50px !important',
						maxWidth: '50px !important',
						// backgroundColor: colors.gray,
					},
					'.MuiDataGrid-columnHeader:hover': {
						background: 'rgba(0, 0, 0, 0.04)',
					},
					'.MuiDataGrid-columnHeader:focus-within': {
						outline: 'none',
						background: 'rgba(0, 0, 0, 0.04)',
					},
					'.MuiBox-root': {
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
						fontSize: '13px',
						fontWeight: '600',
						letterSpacing: '0.14px',
						color: '#545454',
					},
					// '.MuiDataGrid-cell': {
					// 	minWidth: '140px !important',
					// 	maxWidth: '140px !important',
					// },
					'.MuiDataGrid-cellCheckbox.MuiDataGrid-cell': {
						width: '50px !important',
						minWidth: '50px !important',
						maxWidth: '50px !important',
						backgroundColor: colors.ligthGray,
					},
					'.MuiDataGrid-cell .MuiTypography-subtitle1': {
						whiteSpace: 'nowrap',
						display: 'block',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					// Personaliced classes
					'& .metrics-column': {
						width: '50px !important',
						minWidth: '50px !important',
						maxWidth: '50px !important',
						boxShadow: `-2px 0 ${colors.gray} inset`,
					},
				}}
			/>
		)
	);
};

AIPredictionsTable.propTypes = {
	allColumns: PropTypes.array,
	rows: PropTypes.array,
	setCurrentCellId: PropTypes.func,
	setCurrentRowId: PropTypes.func,
	currentRowId: PropTypes.string,
	setSelectedRows: PropTypes.func,
	filters: PropTypes.object,
	setFilters: PropTypes.func,
	selectedRows: PropTypes.array,
};
