import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import { MenuItem, Select, Skeleton } from '@mui/material';
import { CustomPagination } from '../../../../../Components/Shared/CustomPagination';
import { Loader } from '../../../../../Components/Shared/Loader';

import { GET_SCHEMA_NODE_CATEGORIES } from '../../../../../redux/schema.slice';

export const CategoryEditor = ({
	classes,
	currentElement,
	currentCellId,
	currentElementType,
	currentValue = '',
	setCurrentValue,
	name,
	currentCategories,
	disabled = false,
}) => {
	const dispatch = useDispatch();

	const { accessToken } = useSelector((state) => state.user);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { isLoading: isLoadingSchemaState, categories: categoriesState } =
		useSelector((state) => state.schema);

	// const [currentCategories, setCurrentCategories] = useState({});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(4);

	useEffect(() => {
		if (currentElement) setCurrentValue(currentElement.value);
	}, [currentElement]);

	const handleChange = (e) => {
		if (!name) setCurrentValue(e.target.value);
		else setCurrentValue(name, e.target.value);
	};

	const handleChangePage = async (e, newPage) => {
		setPage(newPage);
		await dispatch(
			GET_SCHEMA_NODE_CATEGORIES({
				accessToken,
				taskId: currentTaskState.uuid,
				dispatch,
				nodeName: currentCellId,
				nodeType: currentElementType,
				nodeId: categoriesState[currentElementType].find(
					(cat) => cat.name === currentCellId
				).id,
				page: parseInt(newPage + 1),
			})
		);
	};

	return (
		currentCategories &&
		currentCategories.categories &&
		currentCategories.categories.length > 0 && (
			<Select
				id="Category"
				className={classes.typeCategory}
				value={currentValue}
				name={name || currentCategories.name}
				onChange={handleChange}
				fullWidth
				disabled={disabled}
			>
				<MenuItem value={null}>None</MenuItem>
				{!isLoadingSchemaState ? (
					currentCategories.categories.map((category) => (
						<MenuItem value={category.name} key={category.id}>
							<em>{category.display_name || category.name}</em>
						</MenuItem>
					))
				) : (
					<div>
						<MenuItem>
							<Skeleton
								animation="wave"
								variant="text"
								style={{ height: '24px', minWidth: '175px' }}
							/>
						</MenuItem>
						<MenuItem>
							<Skeleton
								animation="wave"
								variant="text"
								style={{ height: '24px', minWidth: '175px' }}
							/>
						</MenuItem>
						<MenuItem>
							<Skeleton
								animation="wave"
								variant="text"
								style={{ height: '24px', minWidth: '175px' }}
							/>
						</MenuItem>
						<MenuItem>
							<Skeleton
								animation="wave"
								variant="text"
								style={{ height: '24px', minWidth: '175px' }}
							/>
						</MenuItem>
					</div>
				)}
				<CustomPagination
					total={
						currentCategories.total_count || currentCategories.categories.length
					}
					rowsPerPage={rowsPerPage}
					page={page}
					handleChangePage={handleChangePage}
					rowsPerPageOptions={[4]}
					simple
				/>
			</Select>
		)
	);
};

CategoryEditor.propTypes = {
	classes: PropTypes.object,
	currentElement: PropTypes.object,
	currentCellId: PropTypes.string,
	currentElementType: PropTypes.string,
	currentValue: PropTypes.any,
	setCurrentValue: PropTypes.func,
	name: PropTypes.string,
	currentCategories: PropTypes.object,
	disabled: PropTypes.bool,
};
