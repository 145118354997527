import React, { useEffect, useState } from 'react';
// import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Components
import { Dialog, DialogTitle, Grid } from '@mui/material';
// import { formatQuery } from 'react-querybuilder';

import PageTitle from '../../Components/Shared/PageTitle';
import {
	TaskStatus,
	TaskSchema,
	TaskExamples,
	TaskModels,
	TaskAITesting,
	TaskAIPredictions,
	TaskSettings,
} from './components';

// Consts
import { measures } from '../../consts/sizes';

// Redux
import { SET_CURRENT_TASK } from '../../redux/tasks.slice';
import LinearBuffer from '../../Components/Shared/LinearBuffer';
import { Loader } from '../../Components/Shared/Loader';

export const Tasks = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { currentTask: currentTaskState, tasks: tasksState } = useSelector(
		(state) => state.tasks
	);
	const {
		loadingProgress: loadingProgressState,
		loadingBuffer: loadingBufferState,
	} = useSelector((state) => state.loaders);
	// const { schema: schemaState } = useSelector((state) => state.schema);
	// const { query: queryState } = useSelector((state) => state.query);

	const [currentTab, setCurrentTab] = useState('status');
	const [open, setOpen] = useState(true);

	useEffect(() => {
		if (loadingProgressState === 100) setOpen(open);
	}, [loadingProgressState]);

	useEffect(() => {
		if (Object.keys(currentTaskState).length === 0) {
			const currentTaskId = location.pathname.split('/')[2];
			if (tasksState.length > 0) {
				dispatch(SET_CURRENT_TASK(currentTaskId));
			}
		}
	}, [tasksState]);

	useEffect(() => {
		// const query = formatQuery(queryState, 'json');
		// todo
		// dispatch(GET_EXAMPLES({ taskHost, query }));
	}, [currentTaskState]);

	return (
		<>
			{loadingProgressState !== 100 && (
				<Dialog
					open={open}
					fullWidth
					sx={{
						'&>*': {
							display: 'flex',
							justifyContent: 'center',
							fontSize: 'medium !important',
						},
					}}
				>
					{loadingProgressState === 'error' && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							{loadingBufferState}
						</DialogTitle>
					)}
					{loadingProgressState === 0 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Getting and preparing the schema
						</DialogTitle>
					)}
					{loadingProgressState === 20 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Filling schema node categories
						</DialogTitle>
					)}
					{loadingProgressState === 30 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Getting examples and starting editors
						</DialogTitle>
					)}
					{loadingProgressState === 40 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Downloading models
						</DialogTitle>
					)}
					{loadingProgressState === 60 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Downloading predictions
						</DialogTitle>
					)}
					{loadingProgressState === 70 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Setting up the task preferences and settings
						</DialogTitle>
					)}
					{loadingProgressState === 90 && (
						<DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
							Setting up task permissions
						</DialogTitle>
					)}
					{loadingProgressState === 100 && <DialogTitle>Ready</DialogTitle>}

					<LinearBuffer />
				</Dialog>
			)}
			<PageTitle
				title={currentTaskState.name}
				task={currentTaskState}
				tabs={{
					currentTab,
					setCurrentTab,
					tabsArray: [
						{
							label: 'Status',
							linkTo: 'status',
						},
						{
							label: 'Schema',
							linkTo: 'schema',
						},
						{
							label: 'Examples',
							linkTo: 'examples',
						},
						{
							label: 'AI Releases',
							linkTo: 'aireleases',
						},
						{
							label: 'AI Testing',
							linkTo: 'aitesting',
						},
						{
							label: 'AI Predictions',
							linkTo: 'aipredictions',
						},
						{
							label: 'Settings',
							linkTo: 'settings',
						},
					],
				}}
			/>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				sx={{
					px: {
						xs: 1,
						sm: 4,
						md: measures.mdGeneralMargin,
						lg: measures.lgGeneralMargin,
					},
					width: '100%',
					marginTop: '30px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{currentTab === 'status' && <TaskStatus />}
				{currentTab === 'schema' && <TaskSchema />}
				{currentTab === 'examples' && <TaskExamples />}
				{currentTab === 'aireleases' && <TaskModels />}
				{currentTab === 'aitesting' && <TaskAITesting />}
				{currentTab === 'aipredictions' && <TaskAIPredictions />}
				{currentTab === 'settings' && <TaskSettings />}
			</Grid>
		</>
	);
};
