import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';

// Manage roles & permissions
import { HasAccess, usePermify } from '@permify/react-role';

// Components
import { Avatar, Card, Grid, TablePagination } from '@mui/material';
import StatusBar from '../../../Components/Shared/StatusBar';
// import IconButton from '@mui/material/IconButton';
// import Paper from '@mui/material/Paper';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccessDenied from '../../../Components/Core/AccessDenied';

// Redux
import { SET_CURRENT_TASK } from '../../../redux/tasks.slice';

// Styles
import useStyles from './styles';

// Consts
import { defaultRoles } from '../../../consts/rolesAndPermissions';
import { TaskCardSkeleton } from '../../../Components/Shared/Skeleton/TaskCardSkeleton';
import { RESET_EXAMPLES_SLICE } from '../../../redux/examples.slice';
import checkIsAuthorized from '../../../services/checkIsAuthorized';

// Services
import { getLocalDateTime } from '../../../services/date';
import { useWindowSize } from '../../../services/hooks/useWindowResize';

function Tasks() {
	const classes = useStyles();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const windowSize = useWindowSize();
	// const { isAuthorized } = usePermify();

	const { tasks: tasksState } = useSelector((state) => state.tasks);
	const { location: userLocationState } = useSelector((state) => state.user);
	const { dashboard: dashboardLoaderManager } = useSelector(
		(state) => state.loaders
	);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(6);

	useEffect(() => {
		if (windowSize.width > 1535) setRowsPerPage(8);
		else setRowsPerPage(6);
	}, []);

	useEffect(() => {
		if (windowSize.width > 1535) setRowsPerPage(8);
		else setRowsPerPage(6);
	}, [windowSize.width]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectTask = (taskId) => {
		if (localStorage.getItem('oldTaskId') !== taskId)
			dispatch(RESET_EXAMPLES_SLICE());
		window.localStorage.setItem('currentTaskId', taskId);
		dispatch(SET_CURRENT_TASK(taskId));
		navigate(`/tasks/${taskId}`);
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid container spacing={2} className={classes.tasksContainer}>
				{dashboardLoaderManager.tasks &&
					[...Array(5)].map((e, i) => (
						<Grid key={i} item xs={12} sm={6} md={4} lg={4} xl={3}>
							<TaskCardSkeleton />
						</Grid>
					))}
				{!dashboardLoaderManager.tasks &&
					tasksState &&
					tasksState
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((task) => (
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={4}
								xl={3}
								key={task.id}
								onClick={() => handleSelectTask(task.uuid)}
							>
								<Card elevation={3} className={classes.taskCard}>
									<div className={classes.taskIcon}>
										<Avatar
											src={task.icon ? task.icon['download_url'] : null}
											variant="circle"
										/>
									</div>
									<div className={classes.taskName}>{task.name}</div>
									<div className={classes.taskDescription}>
										<b>Description: </b>
										{task.description}
									</div>
									<div className={classes.taskLastModification}>
										<b>Modified at:</b>{' '}
										{getLocalDateTime(task.modified_at, userLocationState)}
									</div>
									<div className={classes.taskStatus}>
										<StatusBar type="task" status={task.status} />
									</div>
								</Card>
							</Grid>
						))}
			</Grid>
			{tasksState && tasksState.length > rowsPerPage && (
				<TablePagination
					rowsPerPageOptions={rowsPerPage === 6 ? [6, 12, 24] : [8, 16, 32]}
					component="div"
					count={tasksState.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</HasAccess>
	);
}

export default Tasks;
