import { v4 as uuidv4 } from 'uuid';

// Components
import {
	Box,
	ThemeProvider,
	Tooltip,
	Typography,
	createTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faXmark,
	faSpinner,
	faBan,
	faTag,
	faFilePen,
	faMessage,
	faChartPie,
} from '@fortawesome/free-solid-svg-icons';
import BarChartIcon from '@mui/icons-material/BarChart';
import { CustomTooltip } from '../../../../Components/Shared/CustomTooltip';

// Services
import { elementTypeToIconMap } from '../../../../services/tasks';
import { createCell } from '../Examples/cellCreation.services';

// Styles
import { colors } from '../../../../consts/colors';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../redux/alerts.slice';
import {
	GET_EXAMPLES,
	UPDATE_EXAMPLE,
	// UPDATE_EXAMPLE
} from '../../../../redux/examples.slice';
import requestFactory from '../../../../services/request.factory';

// Tooltip theme
const tooltipTheme = createTheme({
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: 'black',
					backgroundColor: 'white',
					border: '1px solid gray',
				},
			},
		},
	},
});

export const getCategoryValues = async ({
	id,
	nodeType,
	setIsLocalLoading = null,
	allColumns,
}) => {
	if (!allColumns.length) return;
	const categories = [];
	if (setIsLocalLoading !== null) setIsLocalLoading(true);
	const nodeTypeForRequest =
		nodeType === 'metadata' ? nodeType : `${nodeType}s`;
	const res = await requestFactory(
		'get',
		`/schema/${nodeTypeForRequest}/${id}/categories`
	);
	categories.push({ field: id, categories: res });
	if (setIsLocalLoading !== null) setIsLocalLoading(false);

	return {
		id,
		res,
		categories,
	};
};

const rowStatusToIcon = {
	unlabeled: () => '',
	pending_review: (
		<FontAwesomeIcon icon={faSpinner} style={{ color: colors.red }} />
	),
	labeled: <FontAwesomeIcon icon={faCheck} style={{ color: colors.green }} />,
	rejected: <FontAwesomeIcon icon={faXmark} style={{ color: colors.red }} />,
	null: () => '',
	undefined: () => '',
};

export const getColumns = ({
	setAllColumns,
	classes,
	schemaState,
	predictionsState,
	categoriesState,
	currentTaskState,
	accessToken,
	setCurrentRowId,
	dispatch,
	imagesBufferState,
	documentsBufferState,
	documentSelectedNumPages,
	onDocumentLoadSuccess,
	from,
	setOpenMetricsModal,
}) => {
	const tmpCols = [];

	if (Object.keys(schemaState) && Object.keys(schemaState).length > 0) {
		// AI RELEASE
		tmpCols.push({
			fieldType: 'airelease',
			field: 'airelease',
			sortable: false,
			disableColumnMenu: true,
			headerClassName: 'airelease-column',
			cellClassName: 'airelease-column',
			renderCell: (params) => {
				<div>{params.row.airelease}</div>;
			},
			renderHeader: () => <div>AI Release</div>,
		});

		// ITERATE EACH PREDICTION
		Object.keys(schemaState).forEach((type) => {
			// ITERATE OUTPUTS
			if (
				type === 'outputs' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((output) => {
					if (output.type !== 'shape')
						tmpCols.push({
							id: output.id,
							fieldType: 'output',
							valueType: output.type,
							field: output.name,
							name: output.display_name || output.name,
							sortable: true,
							disableColumnMenu: true,
							renderCell: (params) => {
								if (params.row[output.id] !== undefined) {
									let sorted = [];
									const tmp = predictionsState
										.find((prediction) => prediction.uuid === params.row.id)
										?.outputs.find((out) => out.element === output.name)
										?.value?.scores;
									if (tmp) {
										const sortable = [];

										Object.keys(tmp).forEach((score) => {
											sortable.push([score, tmp[score]]);
										});

										sorted = sortable.sort((a, b) => b[1] - a[1]).slice(0, 5);
									}
									return (
										<CustomTooltip
											placement="left"
											title={
												<div
													style={{ display: 'flex', flexDirection: 'column' }}
												>
													{sorted.map((value) => (
														<div>
															{value[0]} - ({(value[1] * 100).toFixed(2)}%)
														</div>
													))}
												</div>
											}
										>
											<div
												style={{
													width: '100%',
													height: '100%',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												{createCell[output.type]({
													classes,
													cellValue:
														params.row[output.id] || params.row[output.element],
													cellName: output.name,
													imagesBufferState,
													documentsBufferState,
													documentSelectedNumPages,
													onDocumentLoadSuccess,
													categoriesState,
													valueType: 'outputs',
													params,
												})}
											</div>
										</CustomTooltip>
									);
								}
							},
							renderHeader: () => (
								<CustomTooltip title={output.display_name || output.name}>
									<Box
										className={classes.headerCell}
										sx={{ textOverflow: 'ellipsis' }}
									>
										{output.type &&
											elementTypeToIconMap[output.type]('targets')}
										{output.display_name || output.name}
									</Box>
								</CustomTooltip>
							),
							valueGetter: (params) => {
								if (predictionsState && predictionsState.length > 0)
									return params.row[output.id];
							},
						});
				});
			}

			// ITERATE INPUTS
			if (
				type === 'inputs' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((input) => {
					if (input.type !== 'shape')
						tmpCols.push({
							id: input.id,
							fieldType: 'input',
							valueType: input.type,
							field: input.name,
							name: input.display_name || input.name,
							sortable: true,
							disableColumnMenu: true,
							renderHeader: () => (
								<CustomTooltip title={input.display_name || input.name}>
									<Box
										className={classes.headerCell}
										sx={{ textOverflow: 'ellipsis' }}
									>
										{input.type && elementTypeToIconMap[input.type]('input')}
										{input.display_name || input.name}
									</Box>
								</CustomTooltip>
							),
							renderCell: (params) => {
								if (params.row[input.id] !== undefined) {
									return (
										<div
											style={{
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{createCell[input.type]({
												classes,
												cellValue:
													params.row[input.id] || params.row[input.element],
												cellName: input.name,
												imagesBufferState,
												documentsBufferState,
												documentSelectedNumPages,
												onDocumentLoadSuccess,
												categoriesState,
												valueType: 'inputs',
												params,
											})}
										</div>
									);
								}
							},
							valueGetter: (params) => {
								if (predictionsState && predictionsState.length > 0)
									return params.row[input.id];
							},
						});
				});
			}

			// ITERATE METADATA
			if (
				type === 'metadata' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((meta) => {
					if (meta.type !== 'shape')
						tmpCols.push({
							id: meta.id,
							fieldType: 'metadata',
							valueType: meta.type,
							field: meta.name,
							name: meta.display_name || meta.name,
							sortable: true,
							disableColumnMenu: true,
							renderCell: (params) =>
								createCell[meta.type]({
									classes,
									cellValue: params.row[meta.id],
									cellName: meta.name,
									imagesBufferState,
									documentsBufferState,
									onDocumentLoadSuccess,
									categoriesState,
									valueType: 'metadata',
									params,
								}),
							renderHeader: () => (
								<CustomTooltip title={meta.display_name || meta.name}>
									<Box
										className={classes.headerCell}
										sx={{ textOverflow: 'ellipsis' }}
									>
										{meta.type && elementTypeToIconMap[meta.type]('metadata')}
										{meta.display_name || meta.name}
									</Box>
								</CustomTooltip>
							),
							valueGetter: (params) => {
								if (predictionsState && predictionsState.length > 0)
									return params.row[meta.id];
							},
						});
				});
			}
		});
	}

	setAllColumns(tmpCols);
};

export const getRows = ({
	setRows,
	schemaState,
	predictionsState,
	aireleases,
}) => {
	const tmpRows = [];
	let tmpRowData = {};

	if (
		predictionsState &&
		predictionsState.length > 0 &&
		Object.keys(schemaState) &&
		Object.keys(schemaState).length > 0
	) {
		predictionsState.forEach((prediction) => {
			if (
				prediction &&
				((prediction.invalid_data &&
					Array.isArray(prediction.invalid_data) &&
					prediction.invalid_data.length === 0) ||
					!prediction.invalid_data)
			) {
				tmpRowData = {
					...tmpRowData,
					id: prediction.uuid || prediction.id,
					airelease: aireleases.productionAIRelease?.version || '',
				};

				// OUTPUTS
				if (prediction && prediction.outputs && prediction.outputs.length > 0) {
					prediction.outputs.forEach((output) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.outputs &&
							schemaState.outputs.length > 0
						)
							tmp = schemaState.outputs.find(
								(element) => element.name === output.element
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpRowData = {
								...tmpRowData,
								[tmp.id]: output.value.category || '',
							};
					});
				}

				// INPUTS
				if (prediction && prediction.inputs && prediction.inputs.length > 0) {
					prediction.inputs.forEach((input) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.inputs &&
							schemaState.inputs.length > 0
						)
							tmp = schemaState.inputs.find(
								(element) => element.name === input.element
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpRowData = {
								...tmpRowData,
								[tmp.id]:
									typeof input.value === 'boolean'
										? `${input.value}`
										: input.value || '',
							};
					});
				}

				// METADATA
				if (
					prediction &&
					prediction.metadata &&
					prediction.metadata.length > 0
				) {
					prediction.metadata.forEach((meta) => {
						let tmp = [];
						if (
							schemaState &&
							schemaState.metadata &&
							schemaState.metadata.length > 0
						)
							tmp = schemaState.metadata.find(
								(element) => element.name === meta.element
							);
						if (tmp && Object.keys(tmp).length > 0)
							tmpRowData = { ...tmpRowData, [tmp.id]: meta.value || '' };
					});
				}

				if (Object.keys(tmpRowData) && Object.keys(tmpRowData).length > 0)
					tmpRows.push(tmpRowData);

				tmpRowData = {};
			}
		});
	}

	setRows(tmpRows);
};

export const createValidatedTestingObjects = (
	parsedData,
	schemaState,
	dispatch
) => {
	let tmp = {};
	let result = [];

	const createData = (element) => {
		tmp = {
			values: Object.keys(element)
				.filter((el) => el !== 'status')
				.map((el) => ({
					element: el,
					value: element[el],
				})),
		};

		// tmp = {
		// 	status: element.status,
		// 	inputs: [],
		// 	outputs: [],
		// 	metadata: [],
		// };

		const tmpValidationErrors = [];
		// todo: add element validation

		// Object.keys(element).forEach((name) => {
		// 	// INPUTS
		// 	if (schemaState.inputs && schemaState.inputs.length > 0) {
		// 		if (schemaState.inputs.some((input) => input.name === name)) {
		// 			const input = schemaState.inputs.find((input) => input.name === name);

		// 			const validated = validateElement[input.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${input.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.inputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// OUTPUTS
		// 	if (schemaState.outputs && schemaState.outputs.length > 0) {
		// 		if (schemaState.outputs.some((output) => output.name === name)) {
		// 			const output = schemaState.outputs.find(
		// 				(output) => output.name === name
		// 			);

		// 			const validated = validateElement[output.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${output.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.outputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// METADATA
		// 	if (schemaState.metadata && schemaState.metadata.length > 0) {
		// 		if (schemaState.metadata.some((meta) => meta.name === name)) {
		// 			const meta = schemaState.metadata.find((meta) => meta.name === name);

		// 			const validated = validateElement[meta.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${meta.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.metadata.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}
		// });

		if (Object.keys(tmpValidationErrors).length > 0) {
			dispatch(ADD_ALERT({ type: 'error', message: tmpValidationErrors }));
			return null;
		}

		return tmp;
	};

	if (parsedData && parsedData.length > 0) {
		parsedData.forEach((element) => {
			const tmpData = createData(element);
			result.push(tmpData);
		});
	} else {
		result = createData(parsedData);
	}

	return result;
};

// todo: check if neccesary or need to be updated
export const parseValue = (output, element) => {
	const result = output.value;

	if (output.type === 'number') {
		return parseInt(output.value);
	}

	if (element.type === 'Boolean') {
		if (output.checked) return true;
		return false;
	}

	return result;
};
