import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Grid } from '@mui/material';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import QueryBuilder from '../QueryBuilder';

// Styles
import { useStyles } from './styles';
import styles from './styles.module.css';

// Redux
import { GET_EXAMPLES } from '../../../../../../redux/examples.slice';

export const QueryBuilderModal = ({
	open,
	setOpen,
	allColumns,
	query,
	setQuery,
	filters,
	setFilters,
}) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	const [tmpColumns, setTmpColumns] = useState([]);

	useEffect(() => {
		if (allColumns.length > 0)
			setTmpColumns(
				allColumns.filter(
					(column) =>
						column.fieldType !== 'status' &&
						column.fieldType !== 'comments' &&
						column
				)
			);
	}, [allColumns]);

	const handleSaveChanges = async () => {
		if (query.field !== '' && query.value !== '')
			setFilters({
				...filters,
				query: `${query.field}=${query.value}`,
			});
		// await dispatch(
		// 	GET_EXAMPLES({
		// 		taskId: currentTaskState.uuid,
		// 		accessToken,
		// 		dispatch,
		// 		query: urlQuery,
		// 	})
		// );
		// todo: when multiple query supported
		// await dispatch(
		// 	GET_EXAMPLES({
		// 		taskId: currentTaskState.uuid,
		// 		accessToken,
		// 		dispatch,
		// 		query: urlQuery.join('&'),
		// 	})
		// );
		setOpen(false);
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Query builder:"
			content={
				<Grid>
					{/* v2
            <DemoQueryBuilder />
            */}
					{tmpColumns.length > 0 && (
						<QueryBuilder
							tmpColumns={tmpColumns}
							query={query}
							setQuery={setQuery}
						/>
					)}
				</Grid>
			}
			actions={
				<>
					<StandardButton value="Save view" handleClick={handleSaveChanges} />
					<StandardButton
						value="Close"
						handleClick={() => setOpen(!open)}
						close
					/>
				</>
			}
		/>
	);
};

QueryBuilderModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	allColumns: PropTypes.array,
	query: PropTypes.object,
	setQuery: PropTypes.func,
	// todo: when multiple query supported
	// query: PropTypes.array
	filters: PropTypes.object,
	setFilters: PropTypes.func,
};
