import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import useEffect} from 'react';
import PropTypes from 'prop-types';

// Components
import {
	Box,
	Button,
	Checkbox,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import styles from './styles.module.css';
import StandardModal from '../../../Shared/StandardModal';
import requestFactory from '../../../../services/request.factory';
import { GET_SCHEMA } from '../../../../redux/schema.slice';

const purposes = {
	classification: 'Classification',
	regression: 'Regression',
	object_detection: 'Object Detection',
	object_segmentation: 'Object Segmentation',
	question_answering: 'Question Answering',
	summarization: 'Text Summarization',
	speech_recognition: 'Speech Recognition',
	unknown: 'Unknown',
};

const purposesOptions = {
	classification: {
		inputs: [
			'boolean',
			'float',
			'integer',
			'category',
			'text',
			'audio_file',
			'image_file',
		],
		outputs: ['category'],
	},
	regression: {
		inputs: [
			'boolean',
			'float',
			'integer',
			'category',
			'text',
			'audio_file',
			'image_file',
		],
		outputs: ['float', 'integer'],
	},
	object_detection: {
		inputs: ['image_file'],
		outputs: ['shape', 'category'],
	},
	object_segmentation: {
		inputs: ['image_file'],
		outputs: ['shape', 'category'],
	},
	question_answering: {
		inputs: ['text'],
		outputs: ['text'],
	},
	summarization: {
		inputs: ['text'],
		outputs: ['text'],
	},
	speech_recognition: {
		inputs: ['audio_file'],
		outputs: ['text'],
	},
};

const CreateOrUpdateGroupDialog = (props) => {
	const { openDialog, setOpenDialog, mode, prevGroup } = props;

	const dispatch = useDispatch();

	const { schema: schemaState } = useSelector((state) => state.schema);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);

	const [newElement, setNewElement] = useState({
		display_name: '',
		description: '',
		name: '',
		purpose: 'unknown',
		elements: [],
	});

	const [showInputs, setShowInputs] = useState(false);
	const [showOutputs, setShowOutputs] = useState(false);
	const [showMetadata, setShowMetadata] = useState(false);

	useEffect(() => {
		setNewElement({ ...newElement, elements: [] });
	}, []);

	useEffect(() => {
		setNewElement({ ...newElement, elements: [] });
	}, [newElement.purpose]);

	useEffect(() => {
		if (mode === 'update' && prevGroup.name) {
			// add newElement to match currentElementToUpdate
			const tmpElements = [];
			if (prevGroup.inputs)
				prevGroup.inputs.forEach((input) => tmpElements.push(input));
			if (prevGroup.outputs)
				prevGroup.outputs.forEach((output) => tmpElements.push(output));
			if (prevGroup.metadata)
				prevGroup.metadata.forEach((metadata) => tmpElements.push(metadata));
			setNewElement({
				display_name: prevGroup.display_name,
				description: prevGroup.description,
				name: prevGroup.name,
				elements: tmpElements,
			});
		}
	}, [
		mode,
		prevGroup,
		schemaState.inputs,
		schemaState.metadata,
		schemaState.outputs,
	]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setNewElement({ ...newElement, [name]: value });
	};

	const handleShow = (type) => {
		switch (type) {
			case 'inputs':
				setShowInputs(!showInputs);
				break;
			case 'outputs':
				setShowOutputs(!showOutputs);
				break;
			case 'metadata':
				setShowMetadata(!showMetadata);
				break;
			default:
				break;
		}
	};

	const resetValues = () => {
		setNewElement({
			display_name: '',
			description: '',
			name: '',
			elements: [],
		});
		setShowInputs(false);
		setShowOutputs(false);
		setShowMetadata(false);
	};

	const handleCloseDialog = useCallback(() => {
		setOpenDialog(false);
		resetValues();
	}, [setOpenDialog, resetValues]);

	const handleCreateOrUpdateGroup = async () => {
		let res;
		if (mode !== 'update') {
			res = await requestFactory({
				type: 'POST',
				url: `/tasks/${currentTaskState.id}/schema/groups`,
				data: newElement,
				accessToken,
				dispatch,
			});
		} else {
			res = await requestFactory({
				type: 'PUT',
				url: `/tasks/${currentTaskState.id}/schema/groups/${prevGroup.id}`,
				data: newElement,
				accessToken,
				dispatch,
			});
		}
		if (res)
			dispatch(
				GET_SCHEMA({ accessToken, taskId: currentTaskState.id, dispatch })
			);
		// handleCreateNode({
		// 	element: nodeToUpdate && nodeToUpdate.id ? nodeToUpdate.id : null,
		// 	nodeName: groupName,
		// 	nodeDescription: groupDescription,
		// 	value_type: groupPurpose,
		// 	settings: {},
		// 	inputs: groupInputIds,
		// 	targets: groupTargetIds,
		// 	metadata: groupMetadataIds,
		// 	nodeType: 'group',
		// });
		resetValues();
		handleCloseDialog();
	};

	const checkIfAvailableOnPurpose = (element, type) => {
		if (newElement.purpose && newElement.purpose !== 'unknown') {
			if (purposesOptions[newElement.purpose][type].includes(element.type))
				return false;
			return true;
		}
		return false;
	};

	return (
		<StandardModal
			open={openDialog}
			setOpen={handleCloseDialog}
			title="Create group"
			content={
				<Box
					sx={{
						overflowY: 'auto',
						margin: 0,
						paddingRigth: '12px',
					}}
				>
					<DialogContentText sx={{ FontSize: '14px' }}>
						{mode === 'create' ? (
							<>
								You're trying to add a new group for the task. Please fill in
								the following fields (all fields showing as red are required),
								and click on the "Create" button.
							</>
						) : (
							<>
								You're trying to modify the group. Please fill in the following
								fields (all fields showing as red are required), and click on
								the "Update" button.
							</>
						)}
					</DialogContentText>
					<TextField
						id="name"
						name="name"
						label="Name"
						value={newElement.name}
						fullWidth
						required
						sx={{
							mt: 2,
						}}
						onChange={handleChange}
					/>
					{/* <TextField
					id="caption"
					label="Caption"
					value={groupCaption}
					fullWidth
					sx={{
						mt: 2,
					}}
					onChange={(event) => setGroupCaption(event.target.value)}
				/> */}
					<TextField
						id="description"
						name="description"
						label="Description"
						value={newElement.description}
						placeholder="Description"
						fullWidth
						sx={{
							mt: 2,
						}}
						onChange={handleChange}
					/>
					<FormControl
						fullWidth
						sx={{
							mt: 2,
						}}
					>
						<InputLabel id="groupPurposeLabel">Purpose *</InputLabel>
						<Select
							id="purpose"
							labelId="groupPurposeLabel"
							name="purpose"
							label="Purpose"
							disabled={mode === 'update'}
							value={newElement.purpose}
							onChange={handleChange}
						>
							{Object.entries(purposes).map(
								([elementTypeName, elementTypeDisplayName]) => (
									<MenuItem key={elementTypeName} value={elementTypeName}>
										{elementTypeDisplayName}
									</MenuItem>
								)
							)}
						</Select>
					</FormControl>
					<List
						component="nav"
						subheader={
							<Typography
								variant="h6"
								component="div"
								sx={{
									fontWeight: 'bold',
									color: 'text.primary',
								}}
							>
								Elements
							</Typography>
						}
						sx={{
							mt: 2,
						}}
					>
						<ListItemButton onClick={() => handleShow('inputs')}>
							<ListItemText primary="Inputs" />
							{showInputs ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={showInputs} timeout="auto" unmountOnExit>
							<List
								component="div"
								disablePadding
								sx={{ display: 'flex', flexWrap: 'wrap' }}
							>
								{schemaState.inputs?.map((input, index) => (
									<ListItem
										className={styles.label_text}
										key={index}
										sx={{
											pl: 4,
											width: {
												xs: '100%',
												sm: '50%',
												md: '50%',
												lg: '33%',
											},
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													id="input"
													name="input"
													disabled={checkIfAvailableOnPurpose(input, 'inputs')}
													checked={
														newElement.elements.includes(input.id) ||
														newElement.elements.includes(input.name) ||
														newElement.elements.includes(input.display_name)
													}
													onChange={(e) => {
														if (
															e.target.checked &&
															(!newElement.elements.includes(input.id) ||
																!newElement.elements.includes(input.id))
														)
															if (mode !== 'update')
																setNewElement({
																	...newElement,
																	elements: [...newElement.elements, input.id],
																});
															else
																setNewElement({
																	...newElement,
																	elements: [
																		...newElement.elements,
																		input.name,
																	],
																});
														else if (mode !== 'update')
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== input.id
																),
															});
														else
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== input.name
																),
															});
													}}
												/>
											}
											label={input.display_name || input.name}
										/>
									</ListItem>
								))}
							</List>
						</Collapse>
						<ListItemButton onClick={() => handleShow('outputs')}>
							<ListItemText primary="Outputs" />
							{showOutputs ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={showOutputs} timeout="auto" unmountOnExit>
							<List
								component="div"
								disablePadding
								sx={{ display: 'flex', flexWrap: 'wrap' }}
							>
								{schemaState.outputs?.map((output, index) => (
									<ListItem
										className={styles.label_text}
										sx={{
											pl: 4,
											width: {
												xs: '100%',
												sm: '50%',
												md: '50%',
												lg: '33%',
											},
										}}
										key={output.id}
									>
										<FormControlLabel
											control={
												<Checkbox
													id="output"
													name="output"
													disabled={checkIfAvailableOnPurpose(
														output,
														'outputs'
													)}
													checked={
														newElement.elements.includes(output.id) ||
														newElement.elements.includes(output.name)
													}
													onChange={(e) => {
														if (
															e.target.checked &&
															(!newElement.elements.includes(output.id) ||
																!newElement.elements.includes(output.name))
														)
															if (mode !== 'update')
																setNewElement({
																	...newElement,
																	elements: [...newElement.elements, output.id],
																});
															else
																setNewElement({
																	...newElement,
																	elements: [
																		...newElement.elements,
																		output.name,
																	],
																});
														else if (mode !== 'update')
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== output.id
																),
															});
														else
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== output.name
																),
															});
													}}
												/>
											}
											label={output.display_name || output.name}
										/>
									</ListItem>
								))}
							</List>
						</Collapse>
						<ListItemButton onClick={() => handleShow('metadata')}>
							<ListItemText primary="Metadata" />
							{showMetadata ? <ExpandLess /> : <ExpandMore />}
						</ListItemButton>
						<Collapse in={showMetadata} timeout="auto" unmountOnExit>
							<List
								component="div"
								disablePadding
								sx={{ display: 'flex', flexWrap: 'wrap' }}
							>
								{schemaState.metadata?.map((metadata, index) => (
									<ListItem
										className={styles.label_text}
										sx={{
											pl: 4,
											width: {
												xs: '100%',
												sm: '50%',
												md: '50%',
												lg: '33%',
											},
										}}
										key={index}
									>
										<FormControlLabel
											control={
												<Checkbox
													id="metadata"
													name="metadata"
													checked={
														newElement.elements.includes(metadata.id) ||
														newElement.elements.includes(metadata.name)
													}
													onChange={(e) => {
														if (
															e.target.checked &&
															(!newElement.elements.includes(metadata.id) ||
																!newElement.elements.includes(metadata.name))
														)
															if (mode !== 'update')
																setNewElement({
																	...newElement,
																	elements: [
																		...newElement.elements,
																		metadata.id,
																	],
																});
															else
																setNewElement({
																	...newElement,
																	elements: [
																		...newElement.elements,
																		metadata.name,
																	],
																});
														else if (mode !== 'update')
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== metadata.id
																),
															});
														else
															setNewElement({
																...newElement,
																elements: newElement.elements.filter(
																	(e) => e !== metadata.name
																),
															});
													}}
												/>
											}
											label={metadata.display_name || metadata.name}
										/>
									</ListItem>
								))}
							</List>
						</Collapse>
					</List>
				</Box>
			}
			actions={
				<Box>
					<Button
						className={styles.cancel_btn}
						onClick={handleCloseDialog}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						disabled={newElement.name === ''}
						className={styles.create_btn}
						onClick={handleCreateOrUpdateGroup}
						color="primary"
					>
						{mode === 'create' ? 'Create' : 'Update'}
					</Button>
				</Box>
			}
		/>
	);
};

export default CreateOrUpdateGroupDialog;

CreateOrUpdateGroupDialog.propTypes = {
	openDialog: PropTypes.bool,
	setOpenDialog: PropTypes.func,
	mode: PropTypes.string,
	prevGroup: PropTypes.object,
	// onUpdateSchema: PropTypes.func,
	// setIsLoading: PropTypes.func,
	// setSnackbarOpen: PropTypes.func,
	// setSnackbarMessage: PropTypes.func,
	// setSnackbarSeverity: PropTypes.func,
};
