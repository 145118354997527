import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Manage roles & permissions
import { HasAccess, usePermify } from '@permify/react-role';

// Components
import {
	Avatar,
	Checkbox,
	Collapse,
	FormControl,
	Grid,
	IconButton,
	Input,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PageRow from '../../../../Components/Shared/PageRow';
import Section from '../../../../Components/Shared/Section';
import StandardModal from '../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import StatusBar from '../../../../Components/Shared/StatusBar';
import CardItem from '../../../../Components/Shared/CardItem';
import TableSkeleton from '../../../../Components/Shared/Skeleton/TableSkeleton';
import { AddUserPermissionModal } from './components/AddUserPermissionsModal';
import AccessDenied from '../../../../Components/Core/AccessDenied';

// Syles
import { colors } from '../../../../consts/colors';
import useStyles from './styles';
import styles from './styles.module.css';

// Consts
import { measures } from '../../../../consts/sizes';
import { defaultRoles } from '../../../../consts/rolesAndPermissions';

// Redux
import {
	DELETE_TASK,
	GET_TASKS,
	GET_TASK_PERMISSIONS,
	GET_TASK_SETTINGS,
	UPDATE_TASK_SETTINGS,
} from '../../../../redux/tasks.slice';
import {
	CREATE_ORGANIZATION_ROLE_PERMISSION,
	CREATE_ORGANIZATION_USER_PERMISSION,
	DELETE_ORGANIZATION_ROLE_PERMISSION,
	DELETE_ORGANIZATION_USER_PERMISSION,
} from '../../../../redux/organization.slice';
import { DeleteUserPermissionsModal } from './components/DeleteUserPermissionsModal';
import { AddRolePermissionModal } from './components/AddRolePermissionsModal';
import { DeleteRolePermissionModal } from './components/DeleteRolePermissionsModal';
import checkIsAuthorized from '../../../../services/checkIsAuthorized';
import { ConfirmationDialog } from '../../../../Components/Shared/ConfirmationDialog';

const servicesDictionary = {
	active_learning: 'Active Learning service',
	continual_learning: 'Continual Learning service',
	inference: 'Inference service',
	monitoring: 'Monitoring service',
};

export function TaskSettings() {
	const classes = useStyles();

	const { isAuthorized } = usePermify();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const taskNameToDeleteRef = useRef();

	const { accessToken } = useSelector((state) => state.user);
	const { settings: settingsLoaderState, currentTask: currentTaskLoaderState } =
		useSelector((state) => state.loaders.task);
	const {
		isLoading: isLoadingTaskState,
		currentTask: currentTaskState,
		currentTaskSettings: currentTaskSettingsState,
		currentTaskPermissions: currentTaskPermissionsState,
	} = useSelector((state) => state.tasks);
	const {
		info: currentOrganizationInfoState,
		users: organizationUsersState,
		roles: organizationRolesState,
	} = useSelector((state) => state.organization);

	const [open, setOpen] = useState(false);
	const [settings, setSettings] = useState({});
	const [pageUserPermissions, setPageUserPermissions] = useState(0);
	const [rowsPerPageUserPermissions, setRowsPerPageUserPermissions] =
		useState(5);
	const [pageRolesPermissions, setPageRolesPermissions] = useState(0);
	const [rowsPerPageRolesPermissions, setRowsPerPageRolesPermissions] =
		useState(5);
	const [taskNameToDelete, setTaskNameToDelete] = useState('');
	const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);
	const [selectedRolePermissions, setSelectedRolePermissions] = useState([]);
	// User permissions modal
	const [openAddUserPermissionModal, setOpenAddUserPermissionModal] =
		useState(false);
	const [openDeleteUserPermissionsModal, setOpenDeleteUserPermissionsModal] =
		useState(false);
	// Role permissions modal
	const [openAddRolePermissionModal, setOpenAddRolePermissionModal] =
		useState(false);
	const [openDeleteRolePermissionsModal, setOpenDeleteRolePermissionsModal] =
		useState(false);
	const [openConfirmServiceDialog, setOpenConfirmServiceDialog] =
		useState(false);
	const [enableServiceConfirmation, setEnableServiceConfirmation] = useState({
		id: '',
		name: '',
		checked: false,
	});

	const [canCreate, setCanCreate] = useState(false);
	const [canUpdate, setCanUpdate] = useState(false);
	const [canDelete, setCanDelete] = useState(false);

	useEffect(() => {
		checkIsAuthorized({
			isAuthorized,
			setCanCreate,
			permission: 'task.create',
		});
		checkIsAuthorized({
			isAuthorized,
			setCanUpdate,
			permission: 'task.update',
		});
		checkIsAuthorized({
			isAuthorized,
			setCanDelete,
			permission: 'task.delete',
		});
	}, []);

	useEffect(() => {
		if (settings !== currentTaskSettingsState)
			setSettings(currentTaskSettingsState);
	}, [currentTaskSettingsState]);

	const handleUpdateSettings = async () => {
		await dispatch(
			UPDATE_TASK_SETTINGS({
				taskId: currentTaskState.id,
				accessToken,
				data: { services: settings },
			})
		);
		await dispatch(
			GET_TASK_SETTINGS({ accessToken, taskId: currentTaskState.id, dispatch })
		);
	};

	useEffect(() => {
		if (currentTaskState.uuid && settings !== currentTaskSettingsState)
			handleUpdateSettings();
	}, [
		settings?.active_learning?.enabled,
		settings?.inference?.enabled,
		settings?.continual_learning?.enabled,
		settings?.monitoring?.enabled,
	]);

	const handleChange = (e) => {
		const { id, name, value, checked } = e.target;

		if (canUpdate) {
			if (name === 'enabled') {
				setEnableServiceConfirmation({
					id,
					name,
					checked,
				});
				setOpenConfirmServiceDialog(true);
				return;
			}
			if (id === 'monitoring.odd_predictions') {
				setSettings({
					...settings,
					monitoring: {
						...settings.monitoring,
						ood_predictions: {
							...settings.monitoring.ood_predictions,
							[name]: value,
						},
					},
				});
				return;
			}
			setSettings({
				...settings,
				[id]: { ...settings[id], [name]: value },
			});
		}
	};

	const handleChangeUserPermissionsPage = (newPage) => {
		setPageUserPermissions(newPage);
	};

	const handleChangeUserPermissionsRowsPerPage = (event) => {
		setRowsPerPageUserPermissions(event.target.value);
		setPageUserPermissions(0);
	};

	const handleChangeAllUsers = (e) => {
		const { checked } = e.target;
		if (canUpdate) {
			if (checked)
				setSelectedUserPermissions(
					currentTaskPermissionsState.users.map((user) => user.user)
				);
			else setSelectedUserPermissions([]);
		}
	};

	const handleChangeSelectedUser = (e, user) => {
		const { checked } = e.target;
		if (canUpdate) {
			if (checked)
				setSelectedUserPermissions([...selectedUserPermissions, user]);
			else
				setSelectedUserPermissions(
					selectedUserPermissions.filter((usr) => usr !== user)
				);
		}
	};

	const handleChangeRolesPermissionsPage = (newPage) => {
		setPageRolesPermissions(newPage);
	};

	const handleChangeRolesPermissionsRowsPerPage = (event) => {
		setRowsPerPageRolesPermissions(event.target.value);
		setPageRolesPermissions(0);
	};

	const handleChangeAllRoles = (e) => {
		const { checked } = e.target;
		if (canUpdate) {
			if (checked)
				setSelectedRolePermissions(
					currentTaskPermissionsState.roles.map((rol) => rol.role)
				);
			else setSelectedRolePermissions([]);
		}
	};

	const handleChangeSelectedRole = (e, role) => {
		const { checked } = e.target;
		if (canUpdate) {
			if (checked)
				setSelectedRolePermissions([...selectedRolePermissions, role]);
			else
				setSelectedRolePermissions(
					selectedRolePermissions.filter((rol) => rol !== role)
				);
		}
	};

	/** Function to update user and role permissions */
	const handleUpdatePermissions = async (
		e,
		type,
		id,
		selectedPermissions = {
			action: '',
			allow: true,
			resource_type: 'task',
			resource_uuid: '',
		}
	) => {
		const { checked, name } = e.target;

		if (canUpdate) {
			if (type === 'user') {
				await dispatch(
					DELETE_ORGANIZATION_USER_PERMISSION({
						organizationId: currentOrganizationInfoState.id,
						userId: id,
						selectedPermissions: {
							...selectedPermissions,
							resource_uuid:
								selectedPermissions.resource_uuid !== ''
									? selectedPermissions.resource_uuid
									: currentTaskState.uuid,
							action:
								selectedPermissions.action !== ''
									? selectedPermissions.action
									: name,
							allow: !checked,
						},
						accessToken,
						dispatch,
						taskId: currentTaskState.id,
					})
				);
				await dispatch(
					CREATE_ORGANIZATION_USER_PERMISSION({
						organizationId: currentOrganizationInfoState.id,
						userId: id,
						selectedPermissions: {
							...selectedPermissions,
							allow: checked,
							resource_uuid:
								selectedPermissions.resource_uuid !== ''
									? selectedPermissions.resource_uuid
									: currentTaskState.uuid,
							action:
								selectedPermissions.action !== ''
									? selectedPermissions.action
									: name,
						},
						accessToken,
						dispatch,
						taskId: currentTaskState.id,
					})
				);
			}

			if (type === 'role') {
				await dispatch(
					DELETE_ORGANIZATION_ROLE_PERMISSION({
						organizationId: currentOrganizationInfoState.id,
						roleId: id,
						selectedPermissions: {
							...selectedPermissions,
							resource_uuid:
								selectedPermissions?.resource_uuid !== undefined
									? selectedPermissions.resource_uuid
									: currentTaskState.uuid,
							allow: checked,
						},
						accessToken,
						dispatch,
						taskId: currentTaskState.id,
					})
				);
				await dispatch(
					CREATE_ORGANIZATION_ROLE_PERMISSION({
						organizationId: currentOrganizationInfoState.id,
						roleId: id,
						selectedPermissions: {
							...selectedPermissions,
							allow: checked,
							resource_uuid:
								selectedPermissions?.resource_uuid !== undefined
									? selectedPermissions.resource_uuid
									: currentTaskState.uuid,
						},
						accessToken,
						dispatch,
						taskId: currentTaskState.id,
					})
				);
			}
			await dispatch(
				GET_TASK_PERMISSIONS({
					taskId: currentTaskState.uuid || localStorage.getItem('oldTaskId'),
					accessToken,
					dispatch,
				})
			);
		}
	};

	const handleOpenDeleteModal = () => {
		setOpen(!open);
	};

	const confirmDeleteTask = async () => {
		if (taskNameToDelete === currentTaskState.name) {
			await dispatch(
				DELETE_TASK({
					accessToken,
					dispatch,
					taskId: currentTaskState.id,
				})
			);
			await dispatch(
				GET_TASKS({
					accessToken,
					dispatch,
				})
			);
			navigate('/dashboard');
		}
	};

	const handleOpenAddUserPermissionModal = () => {
		setOpenAddUserPermissionModal(!openAddUserPermissionModal);
	};

	const handleOpenDeleteUserPermissionsModal = () => {
		setOpenDeleteUserPermissionsModal(!openDeleteUserPermissionsModal);
	};

	const handleOpenAddRolePermissionModal = () => {
		setOpenAddRolePermissionModal(!openAddRolePermissionModal);
	};

	const handleOpenDeleteRolePermissionsModal = () => {
		setOpenDeleteRolePermissionsModal(!openDeleteRolePermissionsModal);
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid
				container
				spacing={3}
				sx={{
					mb: 3,
				}}
			>
				<Grid item xs={12}>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Task information"
					>
						<div className={classes.taskInfoContainer}>
							<div className={classes.taskInfoAvatar}>
								{!currentTaskLoaderState ? (
									<Avatar
										alt="Organization logo"
										src={
											currentTaskState.icon
												? currentTaskState.icon['download_url']
												: null
										}
										variant="circle"
									>
										{currentTaskState.name[0]}
									</Avatar>
								) : (
									<Skeleton variant="circular" height={40} width={40} />
								)}
							</div>
							{!currentTaskLoaderState ? (
								<h3>{currentTaskState.name}</h3>
							) : (
								<h3>
									<Skeleton
										variant="text"
										style={{
											height: '12px',
											width: '75px',
										}}
									/>
								</h3>
							)}
							{!currentTaskLoaderState ? (
								<div>{currentTaskState.description}</div>
							) : (
								<Skeleton
									variant="text"
									style={{
										height: '12px',
										width: '175px',
									}}
								/>
							)}

							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 6 }}>Status:</div>
								{!currentTaskLoaderState ? (
									<StatusBar type="task" status={currentTaskState.status} />
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '12px',
											width: '75px',
										}}
									/>
								)}
							</div>
						</div>
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="User permissions"
					>
						<div>
							Here you can see the permissions you have in the{' '}
							{currentTaskState.name} task.
						</div>

						{/* <HasAccess
								roles={defaultRoles}
								permissions="organization.read"
								renderAuthFailed={<AccessDenied width="100%" />}
							> */}
						<div className={classes.rolesActions}>
							{/* Add user */}
							<IconButton onClick={handleOpenAddUserPermissionModal}>
								<AddOutlinedIcon sx={{ color: `${colors.blue} !important` }} />
							</IconButton>

							{/* Remove user */}
							<IconButton
								sx={{
									color: `${
										selectedUserPermissions.length > 0 && colors.blue
									} !important`,
								}}
								onClick={handleOpenDeleteUserPermissionsModal}
								disabled={selectedUserPermissions.length === 0}
							>
								<DeleteForeverOutlinedIcon
									sx={{
										color: `${
											selectedUserPermissions.length > 0 && colors.blue
										} !important`,
									}}
								/>
							</IconButton>
						</div>
						<TableContainer>
							<Table sx={{ minWidth: '100%' }} aria-label="simple table">
								<TableHead>
									<TableRow className={classes.scopeTableHeader}>
										<TableCell className={classes.tableTitle}>
											<Checkbox
												color="primary"
												disabled={!canUpdate}
												sx={{ padding: '0px', paddingLeft: '6px' }}
												checked={selectedUserPermissions.length > 0}
												onChange={handleChangeAllUsers}
											/>
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Email
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											First name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Last name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											<div>
												<div>Permissions</div>
												<div className={classes.cellContainer}>
													<div className={classes.cellColumn}>Read</div>
													<div className={classes.cellColumn}>Update</div>
													<div className={classes.cellColumn}>Delete</div>
												</div>
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className={classes.scopeTableBody}>
									{settingsLoaderState.usersPermissions && (
										<>
											<TableSkeleton colsNumber={5} />
											<TableSkeleton colsNumber={5} />
										</>
									)}
									{currentTaskPermissionsState &&
										!settingsLoaderState.usersPermissions &&
										organizationUsersState &&
										organizationUsersState.displayedUsers &&
										organizationUsersState.displayedUsers.length > 0 &&
										currentTaskPermissionsState.users &&
										currentTaskPermissionsState.users.length > 0 &&
										currentTaskPermissionsState.users
											.slice(
												pageUserPermissions * rowsPerPageUserPermissions,
												pageUserPermissions * rowsPerPageUserPermissions +
													rowsPerPageUserPermissions
											)
											.map(
												(permission) =>
													permission.permissions.find(
														(perm) =>
															perm.resource_uuid === currentTaskState.uuid
													) && (
														<TableRow key={uuidv4()}>
															<TableCell className={classes.tableTitle}>
																<Checkbox
																	color="primary"
																	sx={{ padding: '0px', paddingLeft: '6px' }}
																	checked={selectedUserPermissions.includes(
																		permission.user
																	)}
																	disabled={!canUpdate}
																	onChange={(e) =>
																		handleChangeSelectedUser(e, permission.user)
																	}
																/>
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).email
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).first_name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).last_name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																<div className={classes.cellContainer}>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'read') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="read"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'read' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'read' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'read' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="read"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'read')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (perm.action === 'read')
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (perm.action === 'read') {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'update') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="update"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'update' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'update' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="update"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'update')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'update'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'delete') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="delete"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'delete' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'delete' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="delete"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'delete')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'delete'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																</div>
															</TableCell>
														</TableRow>
													)
											)}
								</TableBody>
							</Table>
						</TableContainer>
						{currentTaskPermissionsState &&
							organizationUsersState &&
							organizationUsersState.roles &&
							organizationUsersState.roles.length > 0 &&
							currentTaskPermissionsState.users && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={currentTaskPermissionsState.users.length}
									rowsPerPage={rowsPerPageUserPermissions}
									page={pageUserPermissions}
									onPageChange={handleChangeUserPermissionsPage}
									onRowsPerPageChange={handleChangeUserPermissionsRowsPerPage}
								/>
							)}
						{/* </HasAccess> */}
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Roles permissions"
					>
						<div>
							Here you can see the roles permissions you have in the{' '}
							{currentTaskState.name} task.
						</div>

						{/* <HasAccess
								roles={defaultRoles}
								permissions="organization.read"
								renderAuthFailed={<AccessDenied width="100%" />}
							> */}
						<div className={classes.rolesActions}>
							{/* Add user */}
							<IconButton onClick={handleOpenAddRolePermissionModal}>
								<AddOutlinedIcon sx={{ color: `${colors.blue} !important` }} />
							</IconButton>

							{/* Remove user */}
							<IconButton
								sx={{
									color: `${
										selectedRolePermissions.length > 0 && colors.blue
									} !important`,
								}}
								onClick={handleOpenDeleteRolePermissionsModal}
								disabled={selectedRolePermissions.length === 0}
							>
								<DeleteForeverOutlinedIcon
									sx={{
										color: `${
											selectedRolePermissions.length > 0 && colors.blue
										} !important`,
									}}
								/>
							</IconButton>
						</div>
						<TableContainer>
							<Table sx={{ minWidth: '100%' }} aria-label="simple table">
								<TableHead>
									<TableRow className={classes.scopeTableHeader}>
										<TableCell className={classes.tableTitle}>
											<Checkbox
												color="primary"
												disabled={!canUpdate}
												sx={{ padding: '0px', paddingLeft: '6px' }}
												checked={selectedRolePermissions.length > 0}
												onChange={handleChangeAllRoles}
											/>
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Description
										</TableCell>

										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											<div>
												<div>Permissions</div>
												<div className={classes.cellContainer}>
													<div className={classes.cellColumn}>Read</div>
													<div className={classes.cellColumn}>Update</div>
													<div className={classes.cellColumn}>Delete</div>
												</div>
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className={classes.scopeTableBody}>
									{settingsLoaderState.rolesPermissions && (
										<>
											<TableSkeleton colsNumber={5} />
											<TableSkeleton colsNumber={5} />
										</>
									)}
									{currentTaskPermissionsState &&
										!settingsLoaderState.rolesPermissions &&
										currentTaskPermissionsState.roles &&
										currentTaskPermissionsState.roles
											.slice(
												pageRolesPermissions * rowsPerPageRolesPermissions,
												pageRolesPermissions * rowsPerPageRolesPermissions +
													rowsPerPageRolesPermissions
											)
											.map(
												(role) =>
													role.permissions.find(
														(perm) =>
															perm.resource_uuid === currentTaskState.uuid
													) && (
														<TableRow key={uuidv4()}>
															<TableCell className={classes.tableTitle}>
																<Checkbox
																	color="primary"
																	sx={{ padding: '0px', paddingLeft: '6px' }}
																	checked={selectedRolePermissions.includes(
																		role.role
																	)}
																	disabled={!canUpdate}
																	onChange={(e) =>
																		handleChangeSelectedRole(e, role.role)
																	}
																/>
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationRolesState.find(
																		(rol) => rol.id === role.role
																	).name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationRolesState.find(
																		(rol) => rol.id === role.role
																	).description
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																<div className={classes.cellContainer}>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'read') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'read' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'read' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'read' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'read')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (perm.action === 'read')
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (perm.action === 'read') {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'update') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'update' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'update' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'update')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'update'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'delete') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'delete' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'delete' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'delete')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'delete'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																</div>
															</TableCell>
														</TableRow>
													)
											)}
								</TableBody>
							</Table>
						</TableContainer>
						{currentTaskPermissionsState &&
							currentTaskPermissionsState.roles && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={currentTaskPermissionsState.roles.length}
									rowsPerPage={rowsPerPageRolesPermissions}
									page={pageRolesPermissions}
									onPageChange={handleChangeRolesPermissionsPage}
									onRowsPerPageChange={handleChangeRolesPermissionsRowsPerPage}
								/>
							)}
						{/* </HasAccess> */}
					</CardItem>
					<Grid container spacing={3} sx={{ marginBottom: 3 }}>
						<Grid item xs={12} md={6}>
							<CardItem
								elevation={measures.cardItemElevation}
								sx={{
									display: 'flex',
									padding: 1,
									marginBottom: 3,
								}}
								type="noIcon"
								title="Inference service"
							>
								{!settingsLoaderState.services && settings.inference ? (
									<div className={classes.servicesList}>
										<div>
											<FormControl>
												<Checkbox
													id="inference"
													name="enabled"
													color="primary"
													disabled={!canUpdate}
													checked={
														settings !== {} && settings.inference.enabled
													}
													onChange={handleChange}
												/>
											</FormControl>
										</div>
										<div>Enabled</div>
									</div>
								) : (
									<div className={classes.servicesList}>
										<div>
											<Skeleton
												variant="square"
												style={{
													height: '18px',
													width: '18px',
												}}
											/>
										</div>
										<div>
											<Skeleton
												variant="text"
												style={{
													height: '18px',
													width: '100%',
												}}
											/>
										</div>
									</div>
								)}
							</CardItem>
							<CardItem
								elevation={measures.cardItemElevation}
								sx={{
									display: 'flex',
									padding: 1,
									marginBottom: 3,
								}}
								type="noIcon"
								title="Active Learning service"
							>
								{!settingsLoaderState.services && settings.active_learning ? (
									<>
										<div className={classes.servicesList}>
											<div>
												<FormControl>
													<Checkbox
														id="active_learning"
														name="enabled"
														color="primary"
														disabled={!canUpdate}
														checked={settings.active_learning.enabled || false}
														onChange={handleChange}
													/>
												</FormControl>
											</div>
											<div>Enabled</div>
										</div>
										<Collapse
											in={settings.active_learning.enabled}
											timeout="auto"
											unmountOnExit
											style={{ width: '100%' }}
										>
											<TextField
												label="Query interval (days)"
												className={classes.serviceInput}
												id="active_learning"
												name="query_interval"
												value={settings.active_learning.query_interval}
												type="number"
												required
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
											<TextField
												label="Max. examples per query"
												className={classes.serviceInput}
												id="active_learning"
												name="max_examples_per_query"
												value={settings.active_learning.max_examples_per_query}
												type="number"
												required
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
										</Collapse>
									</>
								) : (
									<div className={classes.servicesList}>
										<div>
											<Skeleton
												variant="square"
												style={{
													height: '18px',
													width: '18px',
												}}
											/>
										</div>
										<div>
											<Skeleton
												variant="text"
												style={{
													height: '18px',
													width: '100%',
												}}
											/>
										</div>
									</div>
								)}
							</CardItem>
						</Grid>
						<Grid item xs={12} md={6}>
							<CardItem
								elevation={measures.cardItemElevation}
								sx={{
									display: 'flex',
									padding: 1,
									marginBottom: 3,
								}}
								type="noIcon"
								title="Continual Learning service"
							>
								{!settingsLoaderState.services &&
								settings.continual_learning ? (
									<div className={classes.servicesList}>
										<div>
											<FormControl>
												<Checkbox
													id="continual_learning"
													name="enabled"
													color="primary"
													disabled={!canUpdate}
													checked={settings.continual_learning.enabled || false}
													onChange={handleChange}
												/>
											</FormControl>
										</div>
										<div>Enabled</div>
									</div>
								) : (
									<div className={classes.servicesList}>
										<div>
											<Skeleton
												variant="square"
												style={{
													height: '18px',
													width: '18px',
												}}
											/>
										</div>
										<div>
											<Skeleton
												variant="text"
												style={{
													height: '18px',
													width: '100%',
												}}
											/>
										</div>
									</div>
								)}
							</CardItem>
							<CardItem
								elevation={measures.cardItemElevation}
								sx={{
									display: 'flex',
									padding: 1,
								}}
								type="noIcon"
								title="Monitoring service"
							>
								{!settingsLoaderState.services && settings.monitoring ? (
									<>
										<div className={classes.servicesList}>
											<div>
												<FormControl>
													<Checkbox
														id="monitoring"
														name="enabled"
														color="primary"
														disabled={!canUpdate}
														checked={settings.monitoring.enabled || false}
														onChange={handleChange}
													/>
												</FormControl>
											</div>
											<div>Enabled</div>
										</div>
										<Collapse
											in={settings.monitoring.enabled}
											timeout="auto"
											unmountOnExit
											style={{ width: '100%' }}
										>
											<TextField
												label="Refresh interval (number of predictions)"
												className={classes.serviceInput}
												id="monitoring"
												name="refresh_interval"
												value={settings.monitoring.refresh_interval}
												type="number"
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
											<TextField
												label="Out-of-distribution minimum sample"
												className={classes.serviceInput}
												id="monitoring.odd_predictions"
												name="min_sample"
												value={settings.monitoring.ood_predictions.min_sample}
												type="number"
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
											<TextField
												label="Out-of-distribution sensitivity (float value between 0 and 1)"
												className={classes.serviceInput}
												id="monitoring.odd_predictions"
												name="sensitivity"
												value={settings.monitoring.ood_predictions.sensitivity}
												type="number"
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
											<TextField
												label="Out-of-distribution smoothing (float value between 0 and 1"
												className={classes.serviceInput}
												id="monitoring.odd_predictions"
												name="smoothing"
												value={settings.monitoring.ood_predictions.smoothing}
												type="number"
												disabled={!canUpdate}
												sx={{
													width: '100%',
													marginTop: '6px',
													marginBottom: '6px',
												}}
												onChange={handleChange}
												onBlur={handleUpdateSettings}
											/>
										</Collapse>
									</>
								) : (
									<div className={classes.servicesList}>
										<div>
											<Skeleton
												variant="square"
												style={{
													height: '18px',
													width: '18px',
												}}
											/>
										</div>
										<div>
											<Skeleton
												variant="text"
												style={{
													height: '18px',
													width: '100%',
												}}
											/>
										</div>
									</div>
								)}
							</CardItem>
						</Grid>
					</Grid>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Delete task"
					>
						<PageRow
							type="danger"
							column1={
								<Section title="Delete this task">
									<span style={{ fontSize: 'small', color: colors.darkGray }}>
										Once deleted, it will be gone forever. Please be certain.
									</span>
								</Section>
							}
							column2={
								<div className={classes.ownerEmails}>
									<div className={classes.ownerEmailsTitle}>
										<HasAccess
											roles={defaultRoles}
											permissions="task.delete"
											renderAuthFailed={
												<StandardButton
													type="disabled"
													value="Delete this task"
												/>
											}
										>
											<StandardButton
												disabled={!canDelete}
												handleClick={handleOpenDeleteModal}
												type="danger"
												value="Delete this task"
											/>
										</HasAccess>
									</div>
								</div>
							}
						/>

						{open && (
							<StandardModal
								open={open}
								setOpen={setOpen}
								title="Delete this task"
								content={
									<div
										className={styles.delete_confirmation_modal}
										style={{ display: 'flex', flexDirection: 'column' }}
									>
										<p>
											Once deleted, it will be gone forever. Please be certain.
										</p>
										<p>
											Before proceeding, please be sure to review the Terms of
											Service regarding account deletion.
										</p>
										<p>
											Enter this task name to confirm.{' '}
											<span style={{ color: 'red' }}>
												{currentTaskState.name}
											</span>
										</p>
										<FormControl
											style={{ marginBottom: '30px', maxWidth: '340px' }}
										>
											<Input
												className={styles.common_input_type}
												ref={taskNameToDeleteRef}
												id="name"
												value={taskNameToDelete}
												onChange={() =>
													setTaskNameToDelete(
														taskNameToDeleteRef.current.children[0].value
													)
												}
											/>
										</FormControl>
									</div>
								}
								actions={
									<>
										<StandardButton
											handleClick={confirmDeleteTask}
											loading={isLoadingTaskState}
											type="danger"
											value="Delete this task"
										/>
										<StandardButton
											handleClick={handleOpenDeleteModal}
											value="Cancel"
											close
										/>
									</>
								}
							/>
						)}
					</CardItem>
				</Grid>
			</Grid>
			{openAddUserPermissionModal && canCreate && (
				<AddUserPermissionModal
					open={openAddUserPermissionModal}
					setOpen={handleOpenAddUserPermissionModal}
					handleUpdatePermissions={handleUpdatePermissions}
				/>
			)}
			{openDeleteUserPermissionsModal && canDelete && (
				<DeleteUserPermissionsModal
					open={openDeleteUserPermissionsModal}
					setOpen={setOpenDeleteUserPermissionsModal}
					selectedUserPermissions={selectedUserPermissions}
					setSelectedUserPermissions={setSelectedUserPermissions}
				/>
			)}
			{openAddRolePermissionModal && canCreate && (
				<AddRolePermissionModal
					open={openAddRolePermissionModal}
					setOpen={handleOpenAddRolePermissionModal}
					handleUpdatePermissions={handleUpdatePermissions}
				/>
			)}
			{openDeleteRolePermissionsModal && canDelete && (
				<DeleteRolePermissionModal
					open={openDeleteRolePermissionsModal}
					setOpen={setOpenDeleteRolePermissionsModal}
					selectedRolePermissions={selectedRolePermissions}
					setSelectedRolePermissions={setSelectedRolePermissions}
				/>
			)}
			{openConfirmServiceDialog && (
				<ConfirmationDialog
					open={openConfirmServiceDialog}
					setOpen={setOpenConfirmServiceDialog}
					title={
						enableServiceConfirmation.checked
							? `Enable ${servicesDictionary[enableServiceConfirmation.id]}?`
							: `Disable ${servicesDictionary[enableServiceConfirmation.id]}?`
					}
					onConfirm={() => {
						// setSettings({
						// 	...settings,
						// 	[id]: { ...settings[id], [name]: checked },
						// });
						setSettings({
							...settings,
							[enableServiceConfirmation.id]: {
								...settings[enableServiceConfirmation.id],
								[enableServiceConfirmation.name]:
									enableServiceConfirmation.checked,
							},
						});
					}}
				/>
			)}
		</HasAccess>
	);
}
/* <Grid
				container
				spacing={3}
				sx={{
					mb: 3,
				}}
			>
				<Grid item xs={12} sm={12} md={4}>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Inference service"
					>
						{!settingsLoaderState.services && settings.inference ? (
							<div className={classes.servicesList}>
								<div>
									<FormControl>
										<Checkbox
											id="inference"
											name="enabled"
											color="primary"
											disabled={!canUpdate}
											checked={settings !== {} && settings.inference.enabled}
											onChange={handleChange}
										/>
									</FormControl>
								</div>
								<div>Enabled</div>
							</div>
						) : (
							<div className={classes.servicesList}>
								<div>
									<Skeleton
										variant="square"
										style={{
											height: '18px',
											width: '18px',
										}}
									/>
								</div>
								<div>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Active Learning service"
					>
						{!settingsLoaderState.services && settings.active_learning ? (
							<>
								<div className={classes.servicesList}>
									<div>
										<FormControl>
											<Checkbox
												id="active_learning"
												name="enabled"
												color="primary"
												disabled={!canUpdate}
												checked={settings.active_learning.enabled || false}
												onChange={handleChange}
											/>
										</FormControl>
									</div>
									<div>Enabled</div>
								</div>
								<Collapse
									in={settings.active_learning.enabled}
									timeout="auto"
									unmountOnExit
									style={{ width: '100%' }}
								>
									<TextField
										label="Query interval (days)"
										className={classes.serviceInput}
										id="active_learning"
										name="query_interval"
										value={settings.active_learning.query_interval}
										type="number"
										required
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
									<TextField
										label="Max. examples per query"
										className={classes.serviceInput}
										id="active_learning"
										name="max_examples_per_query"
										value={settings.active_learning.max_examples_per_query}
										type="number"
										required
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
								</Collapse>
							</>
						) : (
							<div className={classes.servicesList}>
								<div>
									<Skeleton
										variant="square"
										style={{
											height: '18px',
											width: '18px',
										}}
									/>
								</div>
								<div>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Continual Learning service"
					>
						{!settingsLoaderState.services && settings.continual_learning ? (
							<div className={classes.servicesList}>
								<div>
									<FormControl>
										<Checkbox
											id="continual_learning"
											name="enabled"
											color="primary"
											disabled={!canUpdate}
											checked={settings.continual_learning.enabled || false}
											onChange={handleChange}
										/>
									</FormControl>
								</div>
								<div>Enabled</div>
							</div>
						) : (
							<div className={classes.servicesList}>
								<div>
									<Skeleton
										variant="square"
										style={{
											height: '18px',
											width: '18px',
										}}
									/>
								</div>
								<div>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
						}}
						type="noIcon"
						title="Monitoring service"
					>
						{!settingsLoaderState.services && settings.monitoring ? (
							<>
								<div className={classes.servicesList}>
									<div>
										<FormControl>
											<Checkbox
												id="monitoring"
												name="enabled"
												color="primary"
												disabled={!canUpdate}
												checked={settings.monitoring.enabled || false}
												onChange={handleChange}
											/>
										</FormControl>
									</div>
									<div>Enabled</div>
								</div>
								<Collapse
									in={settings.monitoring.enabled}
									timeout="auto"
									unmountOnExit
									style={{ width: '100%' }}
								>
									<TextField
										label="Refresh interval (number of predictions)"
										className={classes.serviceInput}
										id="monitoring"
										name="refresh_interval"
										value={settings.monitoring.refresh_interval}
										type="number"
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
									<TextField
										label="Out-of-distribution minimum sample"
										className={classes.serviceInput}
										id="monitoring.odd_predictions"
										name="min_sample"
										value={settings.monitoring.ood_predictions.min_sample}
										type="number"
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
									<TextField
										label="Out-of-distribution sensitivity (float value between 0 and 1)"
										className={classes.serviceInput}
										id="monitoring.odd_predictions"
										name="sensitivity"
										value={settings.monitoring.ood_predictions.sensitivity}
										type="number"
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
									<TextField
										label="Out-of-distribution smoothing (float value between 0 and 1"
										className={classes.serviceInput}
										id="monitoring.odd_predictions"
										name="smoothing"
										value={settings.monitoring.ood_predictions.smoothing}
										type="number"
										disabled={!canUpdate}
										sx={{
											width: '100%',
											marginTop: '6px',
											marginBottom: '6px',
										}}
										onChange={handleChange}
										onBlur={handleUpdateSettings}
									/>
								</Collapse>
							</>
						) : (
							<div className={classes.servicesList}>
								<div>
									<Skeleton
										variant="square"
										style={{
											height: '18px',
											width: '18px',
										}}
									/>
								</div>
								<div>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid item xs={12} sm={12} md={8}>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Task information"
					>
						<div className={classes.taskInfoContainer}>
							<div className={classes.taskInfoAvatar}>
								{!currentTaskLoaderState ? (
									<Avatar
										alt="Organization logo"
										src={
											currentTaskState.icon
												? currentTaskState.icon['download_url']
												: null
										}
										variant="circle"
									>
										{currentTaskState.name[0]}
									</Avatar>
								) : (
									<Skeleton variant="circular" height={40} width={40} />
								)}
							</div>
							{!currentTaskLoaderState ? (
								<h3>{currentTaskState.name}</h3>
							) : (
								<h3>
									<Skeleton
										variant="text"
										style={{
											height: '12px',
											width: '75px',
										}}
									/>
								</h3>
							)}
							{!currentTaskLoaderState ? (
								<div>{currentTaskState.description}</div>
							) : (
								<Skeleton
									variant="text"
									style={{
										height: '12px',
										width: '175px',
									}}
								/>
							)}

							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ marginRight: 6 }}>Status:</div>
								{!currentTaskLoaderState ? (
									<StatusBar
										type="task"
										status={`${currentTaskState.status.status_code}`}
									/>
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '12px',
											width: '75px',
										}}
									/>
								)}
							</div>
						</div>
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="User permissions"
					>
						<div>
							Here you can see the permissions you have in the{' '}
							{currentTaskState.name} task.
						</div>

						{
              // <HasAccess
								roles={defaultRoles}
								permissions="organization.read"
								renderAuthFailed={<AccessDenied width="100%" />}
							> 
            }
						<div className={classes.rolesActions}>
							{
                // Add user 
              }
							<IconButton onClick={handleOpenAddUserPermissionModal}>
								<AddOutlinedIcon sx={{ color: `${colors.blue} !important` }} />
							</IconButton>

							{
              // Remove user
            }
							<IconButton
								sx={{
									color: `${
										selectedUserPermissions.length > 0 && colors.blue
									} !important`,
								}}
								onClick={handleOpenDeleteUserPermissionsModal}
								disabled={selectedUserPermissions.length === 0}
							>
								<DeleteForeverOutlinedIcon
									sx={{
										color: `${
											selectedUserPermissions.length > 0 && colors.blue
										} !important`,
									}}
								/>
							</IconButton>
						</div>
						<TableContainer>
							<Table sx={{ minWidth: '100%' }} aria-label="simple table">
								<TableHead>
									<TableRow className={classes.scopeTableHeader}>
										<TableCell className={classes.tableTitle}>
											<Checkbox
												color="primary"
												disabled={!canUpdate}
												sx={{ padding: '0px', paddingLeft: '6px' }}
												checked={selectedUserPermissions.length > 0}
												onChange={handleChangeAllUsers}
											/>
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Email
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											First name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Last name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											<div>
												<div>Permissions</div>
												<div className={classes.cellContainer}>
													<div className={classes.cellColumn}>Read</div>
													<div className={classes.cellColumn}>Update</div>
													<div className={classes.cellColumn}>Delete</div>
												</div>
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className={classes.scopeTableBody}>
									{settingsLoaderState.usersPermissions && (
										<>
											<TableSkeleton colsNumber={5} />
											<TableSkeleton colsNumber={5} />
										</>
									)}
									{currentTaskPermissionsState &&
										!settingsLoaderState.usersPermissions &&
										organizationUsersState &&
										organizationUsersState.displayedUsers &&
										organizationUsersState.displayedUsers.length > 0 &&
										currentTaskPermissionsState.users &&
										currentTaskPermissionsState.users.length > 0 &&
										currentTaskPermissionsState.users
											.slice(
												pageUserPermissions * rowsPerPageUserPermissions,
												pageUserPermissions * rowsPerPageUserPermissions +
													rowsPerPageUserPermissions
											)
											.map(
												(permission) =>
													permission.permissions.find(
														(perm) =>
															perm.resource_uuid === currentTaskState.uuid
													) && (
														<TableRow key={uuidv4()}>
															<TableCell className={classes.tableTitle}>
																<Checkbox
																	color="primary"
																	sx={{ padding: '0px', paddingLeft: '6px' }}
																	checked={selectedUserPermissions.includes(
																		permission.user
																	)}
																	disabled={!canUpdate}
																	onChange={(e) =>
																		handleChangeSelectedUser(e, permission.user)
																	}
																/>
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).email
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).first_name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationUsersState.displayedUsers.find(
																		(user) => user.id === permission.user
																	).last_name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																<div className={classes.cellContainer}>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'read') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="read"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'read' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'read' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'read' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="read"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'read')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (perm.action === 'read')
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (perm.action === 'read') {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'update') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="update"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'update' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'update' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="update"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'update')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'update'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{permission.permissions.filter((perm) => {
																			if (perm.action === 'delete') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="delete"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (
																									perm.action === 'delete' &&
																									perm.resource_uuid
																								) {
																									return perm;
																								}
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'delete' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="userPermissions"
																					name="delete"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						permission.permissions.filter(
																							(perm) => {
																								if (perm.action === 'delete')
																									return perm;
																								return false;
																							}
																						)[0]?.allow
																							? permission.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'delete'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'user',
																							permission.user,
																							permission.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																</div>
															</TableCell>
														</TableRow>
													)
											)}
								</TableBody>
							</Table>
						</TableContainer>
						{currentTaskPermissionsState &&
							organizationUsersState &&
							organizationUsersState.roles &&
							organizationUsersState.roles.length > 0 &&
							currentTaskPermissionsState.users && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={currentTaskPermissionsState.users.length}
									rowsPerPage={rowsPerPageUserPermissions}
									page={pageUserPermissions}
									onPageChange={handleChangeUserPermissionsPage}
									onRowsPerPageChange={handleChangeUserPermissionsRowsPerPage}
								/>
							)}
						{
              // </HasAccess> 
            }
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Roles permissions"
					>
						<div>
							Here you can see the roles permissions you have in the{' '}
							{currentTaskState.name} task.
						</div>

						{
              // <HasAccess
								roles={defaultRoles}
								permissions="organization.read"
								renderAuthFailed={<AccessDenied width="100%" />}
							>
            }
						<div className={classes.rolesActions}>
							{
                // Add user
              }
							<IconButton onClick={handleOpenAddRolePermissionModal}>
								<AddOutlinedIcon sx={{ color: `${colors.blue} !important` }} />
							</IconButton>

							{
                // Remove user
              }
							<IconButton
								sx={{
									color: `${
										selectedRolePermissions.length > 0 && colors.blue
									} !important`,
								}}
								onClick={handleOpenDeleteRolePermissionsModal}
								disabled={selectedRolePermissions.length === 0}
							>
								<DeleteForeverOutlinedIcon
									sx={{
										color: `${
											selectedRolePermissions.length > 0 && colors.blue
										} !important`,
									}}
								/>
							</IconButton>
						</div>
						<TableContainer>
							<Table sx={{ minWidth: '100%' }} aria-label="simple table">
								<TableHead>
									<TableRow className={classes.scopeTableHeader}>
										<TableCell className={classes.tableTitle}>
											<Checkbox
												color="primary"
												disabled={!canUpdate}
												sx={{ padding: '0px', paddingLeft: '6px' }}
												checked={selectedRolePermissions.length > 0}
												onChange={handleChangeAllRoles}
											/>
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Name
										</TableCell>
										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											Description
										</TableCell>

										<TableCell
											className={classes.scopeTableHeadCell}
											align="center"
										>
											<div>
												<div>Permissions</div>
												<div className={classes.cellContainer}>
													<div className={classes.cellColumn}>Read</div>
													<div className={classes.cellColumn}>Update</div>
													<div className={classes.cellColumn}>Delete</div>
												</div>
											</div>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody className={classes.scopeTableBody}>
									{settingsLoaderState.rolesPermissions && (
										<>
											<TableSkeleton colsNumber={5} />
											<TableSkeleton colsNumber={5} />
										</>
									)}
									{currentTaskPermissionsState &&
										!settingsLoaderState.rolesPermissions &&
										currentTaskPermissionsState.roles &&
										currentTaskPermissionsState.roles
											.slice(
												pageRolesPermissions * rowsPerPageRolesPermissions,
												pageRolesPermissions * rowsPerPageRolesPermissions +
													rowsPerPageRolesPermissions
											)
											.map(
												(role) =>
													role.permissions.find(
														(perm) =>
															perm.resource_uuid === currentTaskState.uuid
													) && (
														<TableRow key={uuidv4()}>
															<TableCell className={classes.tableTitle}>
																<Checkbox
																	color="primary"
																	sx={{ padding: '0px', paddingLeft: '6px' }}
																	checked={selectedRolePermissions.includes(
																		role.role
																	)}
																	disabled={!canUpdate}
																	onChange={(e) =>
																		handleChangeSelectedRole(e, role.role)
																	}
																/>
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationRolesState.find(
																		(rol) => rol.id === role.role
																	).name
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																{
																	organizationRolesState.find(
																		(rol) => rol.id === role.role
																	).description
																}
															</TableCell>
															<TableCell
																align="center"
																className={classes.scopeCrudElement}
															>
																<div className={classes.cellContainer}>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'read') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'read' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'read' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'read' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'read')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (perm.action === 'read')
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (perm.action === 'read') {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'update') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'update' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'update' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'update')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'update'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'update'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																	<div
																		key={uuidv4()}
																		className={classes.cellColumn}
																	>
																		{role.permissions.filter((perm) => {
																			if (perm.action === 'delete') return perm;
																			return false;
																		}).length > 1 ? (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (
																								perm.action === 'delete' &&
																								perm.resource_uuid
																							) {
																								return perm;
																							}
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action ===
																												'delete' &&
																											perm.resource_uuid
																										) {
																											return perm;
																										}
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete' &&
																										perm.resource_uuid
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		) : (
																			<FormControl>
																				<Checkbox
																					id="inference"
																					name="enabled"
																					color="primary"
																					disabled={!canUpdate}
																					checked={
																						role.permissions.filter((perm) => {
																							if (perm.action === 'delete')
																								return perm;
																							return false;
																						})[0]?.allow
																							? role.permissions.filter(
																									(perm) => {
																										if (
																											perm.action === 'delete'
																										)
																											return perm;
																										return false;
																									}
																							  )[0].allow
																							: false
																					}
																					onChange={(e) =>
																						handleUpdatePermissions(
																							e,
																							'role',
																							role.role,
																							role.permissions.filter(
																								(perm) => {
																									if (
																										perm.action === 'delete'
																									) {
																										return perm;
																									}
																									return false;
																								}
																							)[0]
																						)
																					}
																				/>
																			</FormControl>
																		)}
																	</div>
																</div>
															</TableCell>
														</TableRow>
													)
											)}
								</TableBody>
							</Table>
						</TableContainer>
						{currentTaskPermissionsState &&
							currentTaskPermissionsState.roles && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 25]}
									component="div"
									count={currentTaskPermissionsState.roles.length}
									rowsPerPage={rowsPerPageRolesPermissions}
									page={pageRolesPermissions}
									onPageChange={handleChangeRolesPermissionsPage}
									onRowsPerPageChange={handleChangeRolesPermissionsRowsPerPage}
								/>
							)}
						{
              // </HasAccess> 
            }
					</CardItem>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							marginBottom: 3,
						}}
						type="noIcon"
						title="Delete task"
					>
						<PageRow
							type="danger"
							column1={
								<Section title="Delete this task">
									<span style={{ fontSize: 'small', color: colors.darkGray }}>
										Once deleted, it will be gone forever. Please be certain.
									</span>
								</Section>
							}
							column2={
								<div className={classes.ownerEmails}>
									<div className={classes.ownerEmailsTitle}>
										<HasAccess
											roles={defaultRoles}
											permissions="task.delete"
											renderAuthFailed={
												<StandardButton
													type="disabled"
													value="Delete this task"
												/>
											}
										>
											<StandardButton
												disabled={!canDelete}
												handleClick={handleOpenDeleteModal}
												type="danger"
												value="Delete this task"
											/>
										</HasAccess>
									</div>
								</div>
							}
						/>

						{open && (
							<StandardModal
								open={open}
								setOpen={setOpen}
								title="Delete this task"
								content={
									<div
										className={styles.delete_confirmation_modal}
										style={{ display: 'flex', flexDirection: 'column' }}
									>
										<p>
											Once deleted, it will be gone forever. Please be certain.
										</p>
										<p>
											Before proceeding, please be sure to review the Terms of
											Service regarding account deletion.
										</p>
										<p>
											Enter this task name to confirm.{' '}
											<span style={{ color: 'red' }}>
												{currentTaskState.name}
											</span>
										</p>
										<FormControl
											style={{ marginBottom: '30px', maxWidth: '340px' }}
										>
											<Input
												className={styles.common_input_type}
												ref={taskNameToDeleteRef}
												id="name"
												value={taskNameToDelete}
												onChange={() =>
													setTaskNameToDelete(
														taskNameToDeleteRef.current.children[0].value
													)
												}
											/>
										</FormControl>
										<div
											style={{ display: 'flex', justifyContent: 'flex-start' }}
										>
											<StandardButton
												handleClick={confirmDeleteTask}
												loading={isLoadingTaskState}
												type="danger"
												value="Delete this task"
											/>
										</div>
									</div>
								}
							/>
						)}
					</CardItem>
				</Grid>
			</Grid> 
    */
