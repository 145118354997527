import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';

// Components
import {
	faEye,
	faEyeSlash,
	faDrawPolygon,
	faVectorSquare,
	faCheck,
	faXmark,
	faTrashCan,
	faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { Box, Grid, Tooltip } from '@mui/material';
import SelectTargetModal from './SelectTargetModal';

// Styles
import { useStyles } from './styles';

// Services
import { useWindowSize } from '../../../../../services/hooks/useWindowResize';
import {
	addMouseZoomEventHandler,
	addObjectModifiedEventHandler,
	addObjectMovingEventHandler,
	addObjectScalingEventHandler,
	addObjectSelectionCreatedEventHandler,
	addObjectSelectionUpdatedEventHandler,
	addObjectSelectionClearedEventHandler,
	onCancelPolygon,
	onPersistPolygon,
	onDeleteShape,
	addPanEventHandler,
	disableObjectSelection,
	onDrawPolygon,
} from './ImageFileEditor.services';

// Redux
import { colors } from '../../../../../consts/colors';
import ChipShapesList from '../components/ChipShapesList';
import {
	GET_EXAMPLE_FILE,
	GET_SHAPES,
} from '../../../../../redux/examples.slice';
import { Loader } from '../../../../../Components/Shared/Loader';
import useKey from '../../../../../services/hooks/useKey';
import StandardButton from '../../../../../Components/Shared/Buttons/StandardButton';

const minScale = 0.4;

export const ImageFileEditor = ({
	currentElement,
	currentCellId,
	currentRowId,
	disabled = false,
	handleOpenFileViewer,
}) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const windowSize = useWindowSize();
	const isAltPressed = useKey('Alt');

	const { editor, onReady } = useFabricJSEditor();

	const { accessToken } = useSelector((state) => state.user);
	const {
		examples: examplesState,
		currentExample: currentExampleState,
		currentShapes: currentShapesState,
		imagesBuffer: imagesBufferState,
		isLoading: isLoadingExamplesState,
	} = useSelector((state) => state.examples);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { outputs: outputsState, inputs: inputsState } = useSelector(
		(state) => state.schema.schema
	);

	// const [currentTargets, setCurrentTargets] = useState([]);
	const [openSelectTargetModal, setOpenSelectTargetModal] = useState(false);
	const [selectedOutputs, setSelectedOutputs] = useState([]);

	const [selectedOutput, setSelectedOutput] = useState({ id: '' });
	const [selectedShapeOutput, setSelectedShapeOutput] = useState({
		name: '',
		id: '',
	});
	const [selectedOutputValue, setSelectedOutputValue] = useState(null);

	const [isPolygonMode, setIsPolygonMode] = useState(false);
	const isDrawingPolygon = useRef(false);

	const [isDrawingSegment, setIsDrawingSegment] = useState(false);
	const newSegmentPixelsRef = useRef(null);

	const [shapeVisibility, setShapeVisibility] = useState(true);
	const [currentElementShapes, setCurrentElementShapes] = useState([]);
	const [imageUrl, setImageUrl] = useState('');

	const [containerWidth, setContainerWidth] = useState(0);
	const [containerHeight, setContainerHeight] = useState(0);
	const [scalingFactor, setScalingFactor] = useState(1);
	const [zoom, setZoom] = useState(1);
	const [shapeTypeSelected, setShapeTypeSelected] = useState('');
	const [selectedShape, setSelectedShape] = useState('');
	const [openUpdateShapeDialog, setOpenUpdateShapeDialog] = useState(false);

	const [shapeOutputs, setShapeOutputs] = useState([]);
	const [showOutputShapeSelector, setShowOutputShapeSelector] = useState(false);

	const [isLoading, setIsLoading] = useState(false);
	const [isShapeUpdate, setIsShapeUpdate] = useState(false);

	const getShapes = async () => {
		await dispatch(
			GET_SHAPES({
				taskId: currentTaskState.uuid,
				exampleId: currentExampleState.uuid,
				accessToken,
				dispatch,
			})
		);
	};

	useEffect(() => {
		setIsLoading(true);
		getShapes();
	}, []);

	useEffect(() => {
		if (currentShapesState && currentShapesState.length > 0) {
			const tmpShapes = currentShapesState.filter(
				(shape) => shape.element === currentCellId
			);
			setCurrentElementShapes(tmpShapes);
		} else {
			setCurrentElementShapes([]);
		}
	}, [currentShapesState]);

	const getImage = async () => {
		const res = await dispatch(
			GET_EXAMPLE_FILE({
				taskId: currentTaskState.uuid,
				fileId: currentElement.value,
				accessToken,
				dispatch,
				thumbnail: false,
			})
		);
		if (res.payload.download_url) {
			setImageUrl(res.payload.download_url);
			setScalingFactor(1);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getImage();
	}, [currentElement]);

	useEffect(() => {
		let shapeName = '';

		if (
			currentExampleState.outputs &&
			currentExampleState.outputs.length > 0 &&
			currentExampleState.outputs.find(
				(output) =>
					output.value === selectedShape ||
					(output.value.length > 0 && output.value.includes(selectedShape))
			) &&
			currentExampleState.outputs.find(
				(output) =>
					output.value === selectedShape ||
					(output.value.length > 0 && output.value.includes(selectedShape))
			).element
		)
			shapeName = currentExampleState.outputs.find(
				(output) =>
					output.value === selectedShape ||
					(output.value.length > 0 && output.value.includes(selectedShape))
			).element;

		if (
			currentExampleState.inputs &&
			currentExampleState.inputs.length > 0 &&
			currentExampleState.inputs.find(
				(input) =>
					input.value === selectedShape ||
					(input.value.length > 0 && input.value.includes(selectedShape))
			) &&
			currentExampleState.inputs.find(
				(input) =>
					input.value === selectedShape ||
					(input.value.length > 0 && input.value.includes(selectedShape))
			).element
		)
			shapeName = currentExampleState.inputs.find(
				(input) =>
					input.value === selectedShape ||
					(input.value.length > 0 && input.value.includes(selectedShape))
			).element;

		if (shapeName)
			setSelectedShapeOutput(
				(outputsState &&
					outputsState.length > 0 &&
					outputsState.find((output) => output.name === shapeName)) ||
					(inputsState &&
						inputsState.length > 0 &&
						inputsState.find((input) => input.name === shapeName))
			);
	}, [selectedShape]);

	useEffect(() => {
		if (currentElement && editor?.canvas.contextContainer !== null) {
			const canvasContainer = document.getElementById('canvas-container');
			setContainerWidth(canvasContainer.offsetWidth);
			setContainerHeight(canvasContainer.offsetHeight);

			editor?.canvas.off();
			if (editor && editor.canvas)
				if (isAltPressed) {
					editor.canvas.defaultCursor = 'move';
					editor?.canvas.setCursor('move');
				} else {
					editor.canvas.defaultCursor = 'default';
					editor?.canvas.setCursor('default');
				}

			addMouseZoomEventHandler({
				editor,
				setZoom,
				scalingFactor,
				containerWidth,
				containerHeight,
			});
			addObjectMovingEventHandler({ editor });
			addObjectScalingEventHandler({ editor });
			addObjectModifiedEventHandler({
				editor,
				fabric,
				scalingFactor,
				currentElementShapes,
				dispatch,
				taskId: currentTaskState.uuid,
				exampleId: currentExampleState.uuid,
				setSelectedShape,
				accessToken,
			});
			addPanEventHandler({ editor });
			if (isPolygonMode || isAltPressed) {
				disableObjectSelection({ editor, isPolygonMode });
			} else {
				addObjectSelectionCreatedEventHandler({
					editor,
					setSelectedShape,
					currentElementShapes,
					isPolygonMode,
				});
				addObjectSelectionUpdatedEventHandler({
					editor,
					setSelectedShape,
					currentElementShapes,
				});
				addObjectSelectionClearedEventHandler({
					editor,
					setSelectedShape,
					setSelectedShapeOutput,
					currentElementShapes,
				});
			}

			fabric.Image.fromURL(imageUrl, (img) => {
				if (img.width > img.height) {
					setScalingFactor(containerWidth / img.width);
					img.scaleX = containerWidth / img.width;
					img.scaleY = containerWidth / img.width;
				} else {
					setScalingFactor(containerHeight / img.height);
					img.scaleX = containerHeight / img.height;
					img.scaleY = containerHeight / img.height;
				}

				editor?.canvas.setHeight(canvasContainer.offsetHeight);
				editor?.canvas.setWidth(canvasContainer.offsetWidth);

				editor?.canvas.setBackgroundImage(img);
				editor?.canvas.renderAll();
			});

			editor?.canvas.clear();
			if (shapeVisibility) {
				currentElementShapes.forEach((s) => {
					const polygon = new fabric.Polygon(
						s.polygon.map((p) => ({
							x: p.x * scalingFactor,
							y: p.y * scalingFactor,
						})),
						{
							id: s.id,
							stroke: 'red',
							strokeWidth: 0.5,
							fill: 'rgba(255,0,0,0.2)',
							transparentCorners: false,
						}
					);
					polygon.points.forEach((point) => {
						const circle = new fabric.Circle({
							left: point.x,
							top: point.y,
							radius: 0.5,
							fill: 'red',
							selectable: false,
							hasBorders: false,
							hasControls: false,
							originX: 'center',
							originY: 'center',
						});
						editor?.canvas.add(circle);
					});
					polygon.setCoords();
					editor?.canvas.add(polygon);
				});
			}
		}
	}, [
		currentElement,
		editor?.canvas,
		shapeVisibility,
		imageUrl,
		// todo: review if removing currentElementShapes affect
		currentElementShapes,
		scalingFactor,
		isPolygonMode,
		windowSize,
		setSelectedShape,
		isAltPressed,
	]);

	useEffect(() => {
		if (!openSelectTargetModal && !isPolygonMode) setSelectedShapeOutput({});
	}, [openSelectTargetModal]);

	const handleCreateShape = () => {
		setOpenSelectTargetModal(true);
	};

	const handleEditShape = () => {
		setIsShapeUpdate(true);
		setOpenSelectTargetModal(true);
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Box className={classes.topMenu}>
						{!isPolygonMode && selectedShape === '' && !disabled && (
							<>
								<Tooltip title="Show/Hide shapes">
									<FontAwesomeIcon
										className={classes.topMenuIcon}
										icon={shapeVisibility ? faEye : faEyeSlash}
										onClick={() => setShapeVisibility(!shapeVisibility)}
									/>
								</Tooltip>
								<FontAwesomeIcon
									className={classes.topMenuIcon}
									icon={faVectorSquare}
									onClick={handleCreateShape}
								/>
								<FontAwesomeIcon
									className={classes.topMenuIcon}
									icon={faDrawPolygon}
									onClick={() => {
										setIsPolygonMode(true);
										handleCreateShape();
									}}
								/>
							</>
						)}
						{selectedShape !== '' && !disabled && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									color: colors.blue,
									margin: '0px 6px',
									padding: '0px 6px',
								}}
							>
								<div>Edit shape:</div>
								<Tooltip title="Confirm">
									<FontAwesomeIcon
										className={classes.topMenuIcon}
										icon={faCheck}
										onClick={() => {
											setSelectedShape('');
											setSelectedShapeOutput({});
										}}
									/>
								</Tooltip>
								<Tooltip title="Edit shape">
									<FontAwesomeIcon
										className={classes.topMenuIcon}
										icon={faPenToSquare}
										onClick={() => handleEditShape()}
									/>
								</Tooltip>
								<Tooltip title="Delete shape">
									<FontAwesomeIcon
										className={classes.topMenuIcon}
										icon={faTrashCan}
										onClick={() =>
											onDeleteShape({
												taskId: currentTaskState.uuid,
												exampleId: currentExampleState.uuid,
												currentShapesState,
												accessToken,
												dispatch,
												shapeId: selectedShape,
												setSelectedShape,
												currentRowId,
												currentExampleState,
												selectedShapeOutput,
											})
										}
									/>
								</Tooltip>
							</div>
						)}
						{isPolygonMode && !disabled && (
							<div
								style={{
									// display: 'flex',
									// justifyContent: 'center',
									// alignItems: 'center',
									color: colors.blue,
									margin: '0px 6px',
									padding: '0px 6px',
								}}
							>
								<div>Polygon shape:</div>
								<FontAwesomeIcon
									className={classes.topMenuIcon}
									icon={faCheck}
									onClick={() =>
										onPersistPolygon({
											setIsPolygonMode,
											isDrawingPolygon,
											currentElement,
											editor,
											scalingFactor,
											dispatch,
											currentRowId,
											selectedOutput,
											taskId: currentTaskState.uuid,
											exampleId: currentExampleState.uuid,
											accessToken,
											selectedOutputs,
											selectedShapeOutput,
											currentExampleState,
											examplesState,
										})
									}
								/>
								<FontAwesomeIcon
									className={classes.topMenuIcon}
									icon={faXmark}
									onClick={() =>
										onCancelPolygon({
											editor,
											setIsPolygonMode,
											isDrawingPolygon,
											setSelectedOutput,
										})
									}
								/>
							</div>
						)}
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					sx={{
						display: 'flex',
						justifyContent: 'end',
						alignItems: 'center',
						fontSize: '0.8rem',
						color: colors.blue,
					}}
				>
					* Press Alt and drag to move arround
				</Grid>
				<Grid container sx={{ marginBottom: '6px' }}>
					{disabled && (
						<>
							<Grid
								item
								xs={12}
								md={6}
								sx={{
									display: 'flex',
									alignItems: 'center',
									fontSize: '0.8rem',
									color: colors.blue,
								}}
							>
								<StandardButton
									value="Back to table"
									handleClick={handleOpenFileViewer}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sx={{
									display: 'flex',
									justifyContent: 'start',
									alignItems: 'center',
									fontSize: '0.8rem',
									color: colors.blue,
								}}
							>
								* Press Alt and drag to move arround
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					outline: `1px solid ${colors.blue}`,
					cursor: isAltPressed ? 'pointer' : 'auto',
				}}
			>
				<Box
					id="canvas-container"
					sx={{
						minHeight: '500px',
						// height: { xs: '40vh', sm: '50vh', md: '50vh' },
						// maxHeight: '500px',
						width: '100%',
						cursor: isAltPressed ? 'pointer' : 'auto',
					}}
				>
					{!isLoading ? (
						<FabricJSCanvas onReady={onReady} />
					) : (
						<Loader size="M" />
					)}
				</Box>
			</div>
			{!disabled && (
				<ChipShapesList
					editor={editor}
					currentCellId={currentCellId}
					selectedShape={selectedShape}
					setSelectedShape={setSelectedShape}
				/>
			)}
			{!disabled && openSelectTargetModal && (
				<SelectTargetModal
					onClose={setOpenSelectTargetModal}
					open={openSelectTargetModal}
					selectedShape={selectedShape}
					selectedOutput={selectedOutput}
					setSelectedOutput={setSelectedOutput}
					selectedShapeOutput={selectedShapeOutput}
					setSelectedShapeOutput={setSelectedShapeOutput}
					selectedOutputValue={selectedOutputValue}
					setSelectedOutputValue={setSelectedOutputValue}
					selectedOutputs={selectedOutputs}
					setSelectedOutputs={setSelectedOutputs}
					editor={editor}
					zoom={zoom}
					scalingFactor={scalingFactor}
					containerWidth={containerWidth}
					containerHeight={containerHeight}
					currentElement={currentElement}
					currentElementShapes={currentElementShapes}
					setCurrentElementShapes={setCurrentElementShapes}
					isPolygonMode={isPolygonMode}
					setIsPolygonMode={setIsPolygonMode}
					isDrawingPolygon={isDrawingPolygon}
					fabric={fabric}
					setShapeVisibility={setShapeVisibility}
					currentRowId={currentRowId}
					taskId={currentTaskState.uuid}
					exampleId={currentExampleState.uuid}
					accessToken
					isShapeUpdate={isShapeUpdate}
					setIsShapeUpdate={setIsShapeUpdate}
				/>
			)}
		</>
	);
};

ImageFileEditor.propTypes = {
	currentElement: PropTypes.object,
	currentCellId: PropTypes.string,
	currentRowId: PropTypes.string,
	disabled: PropTypes.bool,
	handleOpenFileViewer: PropTypes.func,
};
