/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import {
	Box,
	Grid,
	Typography,
	Skeleton,
	/* Toolbar  Link */
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import FixedFlow from '../../../../Components/Core/Task/Schema/FixedFlow';
import CardItem from '../../../../Components/Shared/CardItem';
import Training from '../../../Dashboard/Training';
import Documentation from '../../../Dashboard/Documentation';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import StandardModal from '../../../../Components/Shared/StandardModal';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import StatusBar from '../../../../Components/Shared/StatusBar';
import { Loader } from '../../../../Components/Shared/Loader';

// Styles
import useStyles from './styles';
import { measures } from '../../../../consts/sizes';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { getLocalDateTime } from '../../../../services/date';

import requestFactory from '../../../../services/request.factory';

export function TaskStatus() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const {
		currentTask: currentTaskState,
		currentTaskSettings: currentTaskSettingsState,
	} = useSelector((state) => state.tasks);
	const { totalExamples: totalExamplesState } = useSelector(
		(state) => state.examples
	);
	const { accessToken } = useSelector((state) => state.user);
	const { productionAIRelease: productionAIReleaseState } = useSelector(
		(state) => state.aireleases
	);
	const {
		info: taskInfoLoaderState,
		services: taskServiceLoaderState,
		productionRelease: taskProductionReleaseLoaderState,
	} = useSelector((state) => state.loaders.task.status);
	const { location: userLocationState } = useSelector((state) => state.user);

	const [servicesStatus, setServicesStatus] = useState({
		active_learning: 0,
		continual_learning: 0,
		monitoring: 0,
		inference: 0,
	});

	// Modals
	const [openShowSchema, setOpenShowSchema] = useState(false);

	const handleCloseShowModal = () => {
		setOpenShowSchema(false);
	};

	const getServicesStatus = async () => {
		let tmpServices = servicesStatus;
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/inference`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				inference: res.status.status_code,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/monitoring`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				monitoring: res.status.status_code,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/active-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				active_learning: res.status.status_code,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/continual-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				continual_learning: res.status.status_code,
			};
		});

		setServicesStatus(tmpServices);
	};

	useEffect(() => {
		if (currentTaskState.uuid) getServicesStatus();
	}, [currentTaskSettingsState]);

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid container spacing={3}>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							// marginBottom: 3,
						}}
						type="noIcon"
						title="Status"
					>
						{!taskInfoLoaderState ? (
							<Box className={classes.descriptionContent}>
								{currentTaskState?.status && (
									<>
										<StatusBar type="task" status={currentTaskState.status} />
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<h3>Name:</h3> {`  ${currentTaskState.name}` || ''}
										</div>
										<h3>Description:</h3>
										{currentTaskState.description || ''}
										<h3>Modified at:</h3>
										{getLocalDateTime(
											currentTaskState.modified_at,
											userLocationState
										)}
									</>
								)}
							</Box>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Name:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '50%',
										}}
									/>
								</div>
								<h3>Description:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '20%',
									}}
								/>
								<h3>Modified at:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Services status"
					>
						{!taskServiceLoaderState &&
						currentTaskSettingsState.active_learning &&
						currentTaskSettingsState.continual_learning &&
						currentTaskSettingsState.inference &&
						currentTaskSettingsState.monitoring ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<h3>Active learning:</h3>
								{servicesStatus.active_learning !== 0 ? (
									currentTaskSettingsState.active_learning.enabled ? (
										<StatusBar statusCode={servicesStatus.active_learning} />
									) : (
										<StatusBar statusCode="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Coninual learning:</h3>
								{servicesStatus.continual_learning !== 0 ? (
									currentTaskSettingsState.continual_learning.enabled ? (
										<StatusBar statusCode={servicesStatus.continual_learning} />
									) : (
										<StatusBar statusCode="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Inference:</h3>
								{servicesStatus.inference !== 0 ? (
									currentTaskSettingsState.inference.enabled ? (
										<StatusBar statusCode={servicesStatus.inference} />
									) : (
										<StatusBar statusCode="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								<h3>Monitoring:</h3>
								{servicesStatus.monitoring !== 0 ? (
									currentTaskSettingsState.monitoring.enabled ? (
										<StatusBar statusCode={servicesStatus.monitoring} />
									) : (
										<StatusBar statusCode="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<h3>Active learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Coninual learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Inference:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3>Monitoring:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Models */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Production AI release"
					>
						{!taskProductionReleaseLoaderState ? (
							productionAIReleaseState &&
							Object.keys(productionAIReleaseState).length > 0 ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
										}}
									>
										<h3>Version:</h3>
										{productionAIReleaseState.docker_image?.tag || ''}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
										}}
									>
										<h3>ID:</h3>
										{productionAIReleaseState.id}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<h3>Creation date:</h3>
										{getLocalDateTime(
											productionAIReleaseState.created_at,
											userLocationState
										)}
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<StandardButton
											value="Show schema"
											handleClick={() => setOpenShowSchema(true)}
										/>
									</div>
								</div>
							) : (
								<div>No Production releases found</div>
							)
						) : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Version:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>ID:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Creation date:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>

					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Total examples"
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: 12,
							}}
						>
							<StorageIcon sx={{ color: '#666666' }} />
							<Typography
								variant="span"
								sx={{
									fontSize: 22,
									color: '#666666',
								}}
							>
								{typeof totalExamplesState === 'number' ? (
									totalExamplesState
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '150px',
										}}
									/>
								)}
							</Typography>
						</div>
					</CardItem>
				</Grid>

				<Grid container spacing={3} sx={{ paddingLeft: 3, marginTop: 3 }}>
					<Grid item xs={12} md={4}>
						{/* Training */}
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								marginBottom: 3,
							}}
							title="Training"
							titleicon={<ChangeHistoryIcon />}
						>
							<Training />
						</CardItem>
					</Grid>

					{/* <Grid item xs={6}>
						{
              // Documentation
            }
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								marginBottom: 3,
							}}
							title="Documentation"
							titleicon={<MenuBookIcon />}
						>
							<Documentation />
						</CardItem>
					</Grid> 
          */}
				</Grid>
			</Grid>
			{openShowSchema && (
				<StandardModal
					open={openShowSchema}
					setOpen={handleCloseShowModal}
					title="Production AI Release schema"
					content={
						<Box sx={{ height: '584px' }}>
							<FixedFlow tmpSchema={productionAIReleaseState.task_schema} />
						</Box>
					}
					actions={
						<StandardButton
							close
							value="Close"
							handleClick={handleCloseShowModal}
						/>
					}
				/>
			)}
		</HasAccess>
	);
}
