import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	statusContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		padding: '3px 6px',
		borderRadius: '6px',
	},
});

export default useStyles;
