import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';

// Components
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControlLabel, Input, Switch, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	faFileCsv,
	faKeyboard,
	faCloudArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import { Loader } from '../../../../../../Components/Shared/Loader';

// Styles
import { useStyles } from './styles';
import { elementTypeToIconMap } from '../../../../../../services/tasks';
import styles from './styles.module.css';

// Services
import { createCSVTemplate, parseCSVFile } from '../../csv.services';
import { createValidatedTestingObjects } from '../../aitesting.services';

// Redux
import { GET_EXAMPLE_FILE } from '../../../../../../redux/examples.slice';
import {
	IMAGE_FILE_UPLOAD_ERROR,
	IMAGE_FILE_UPLOAD_SUCCESS,
} from '../../../../../../AlertsList/examplesAlerts';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../../../redux/alerts.slice';
import { CREATE_TASK_FILE } from '../../../../../../redux/tasks.slice';
import requestFactory from '../../../../../../services/request.factory';
import { CREATE_TEST } from '../../../../../../redux/testing.slice';
import { getLocalDateTime } from '../../../../../../services/date';

export const CreateOrUpdateAITestingModal = ({
	open,
	setOpen,
	allColumns,
	schemaState,
	step = 0,
	setOpenDeleteExampleModal,
}) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const hiddenFileInput = useRef(null);

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);
	const { currentExample: currentExampleState } = useSelector(
		(state) => state.examples
	);
	const { tests: testsState } = useSelector((state) => state.tests);
	const { categories: categoriesState } = useSelector((state) => state.schema);
	const { AIReleases: AIReleasesState } = useSelector(
		(state) => state.aireleases
	);
	const { location: userLocationState } = useSelector((state) => state.user);

	const [creationStep, setCreationStep] = useState(step);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const [csvReport, setCsvReport] = useState({});
	// const [CSVTmpData, setCSVTmpData] = useState([]);
	const [, /* localLoading */ setIsLocalLoading] = useState(false);
	const [manualElementCategories, setManualElementCategories] = useState({});
	const [manualExample, setManualExample] = useState({
		values: [],
		targets: [],
		// tags: { elements: [], groups: [] },
	});
	const [tmpImage, setTmpImage] = useState({});

	// const getThumbnails = async () => {
	// 	const tmpArray = manualExample.values.filter(
	// 		(example) =>
	// 			example.value !== '' && Object.keys(example).includes('thumbnailUrl')
	// 	);
	// 	if (manualExample.values.length > 0) {
	// 		if (tmpArray.length > 0) {
	// 			await Promise.all(
	// 				tmpArray.map(async (example) =>
	// 					requestFactory({
	// 						type: 'GET',
	// 						url: `/tasks/${currentTaskState.id}/files/${example.value}`,
	// 						accessToken,
	// 						dispatch,
	// 					})
	// 				)
	// 			).then((res) => {
	// 				const tmpExamples = manualExample.values.map((example) => {
	// 					if (res.find((r) => r.id === example.value))
	// 						return {
	// 							...example,
	// 							thumbnailUrl: res.find((r) => r.id === example.value)[
	// 								'download_url'
	// 							],
	// 						};
	// 					return example;
	// 				});
	// 				setManualExample({ ...manualExample, values: tmpExamples });
	// 			});
	// 		}
	// 	}
	// };

	// const getExample = async () => {
	// 	let tmp = manualExample;
	// 	allColumns.forEach(async (column) => {
	// 		if (column.field !== 'status' && column.field !== 'edit') {
	// 			if (
	// 				column.valueType === 'generic_file' ||
	// 				column.valueType === 'document_file' ||
	// 				column.valueType === 'image_file' ||
	// 				column.valueType === 'video_file' ||
	// 				column.valueType === 'audio_file'
	// 			) {
	// 				if (
	// 					currentExampleState[
	// 						column.fieldType !== 'metadata'
	// 							? `${column.fieldType}s`
	// 							: column.fieldType
	// 					] &&
	// 					currentExampleState[
	// 						column.fieldType !== 'metadata'
	// 							? `${column.fieldType}s`
	// 							: column.fieldType
	// 					].find((example) => example.element === column.field) &&
	// 					currentExampleState[
	// 						column.fieldType !== 'metadata'
	// 							? `${column.fieldType}s`
	// 							: column.fieldType
	// 					].find((example) => example.element === column.field).value &&
	// 					currentExampleState[
	// 						column.fieldType !== 'metadata'
	// 							? `${column.fieldType}s`
	// 							: column.fieldType
	// 					].find((example) => example.element === column.field).value !== ''
	// 				) {
	// 					Promise.resolve(
	// 						requestFactory({
	// 							type: 'GET',
	// 							url: `/tasks/${currentTaskState.id}/files/${
	// 								currentExampleState[
	// 									column.fieldType !== 'metadata'
	// 										? `${column.fieldType}s`
	// 										: column.fieldType
	// 								].find((example) => example.element === column.field).value
	// 							}`,
	// 							accessToken,
	// 							dispatch,
	// 						})
	// 					).then((res) => {
	// 						const tmpExamples = tmp.values.map((example) => {
	// 							if (example.value === res.id)
	// 								return { ...example, thumbnailUrl: res.download_url };
	// 							return example;
	// 						});
	// 						setManualExample({ ...tmp, values: tmpExamples });
	// 					});

	// 					tmp = {
	// 						...tmp,
	// 						values: [
	// 							...tmp.values,
	// 							{
	// 								element: column.field,
	// 								value:
	// 									currentExampleState[
	// 										column.fieldType !== 'metadata'
	// 											? `${column.fieldType}s`
	// 											: column.fieldType
	// 									].find((example) => example.element === column.field)
	// 										.value || '',
	// 								thumbnailUrl: '',
	// 								tmpInfo: '',
	// 								isLoading: false,
	// 							},
	// 						],
	// 					};
	// 				}
	// 			} else {
	// 				tmp = {
	// 					...tmp,
	// 					labeling_status: 'unlabeled',
	// 					values: [
	// 						...tmp.values,
	// 						currentExampleState[
	// 							column.fieldType !== 'metadata'
	// 								? `${column.fieldType}s`
	// 								: column.fieldType
	// 						] &&
	// 						currentExampleState[
	// 							column.fieldType !== 'metadata'
	// 								? `${column.fieldType}s`
	// 								: column.fieldType
	// 						].find((example) => example.element === column.field)
	// 							? currentExampleState[
	// 									column.fieldType !== 'metadata'
	// 										? `${column.fieldType}s`
	// 										: column.fieldType
	// 							  ].find((example) => example.element === column.field)
	// 							: {
	// 									element: column.field,
	// 									value: '',
	// 							  },
	// 					],
	// 				};
	// 			}
	// 		}
	// 	});
	// 	setManualExample(tmp);
	// };

	useEffect(() => {
		if (!open) {
			setCreationStep(0);
			setFileToUpload(null);
		}

		if (allColumns.length > 0) {
			let tmp = manualExample;
			allColumns.forEach((column) => {
				if (column.field !== 'status' && column.field !== 'edit')
					if (
						column.valueType === 'generic_file' ||
						column.valueType === 'document_file' ||
						column.valueType === 'image_file' ||
						column.valueType === 'video_file' ||
						column.valueType === 'audio_file'
					) {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
									thumbnailUrl: '',
									tmpInfo: '',
									isLoading: false,
								},
							],
						};
					} else if (column.valueType === 'boolean') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: false,
								},
							],
						};
					} else if (column.valueType === 'datetime') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
								},
							],
						};
					} else {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
								},
							],
						};
					}
				setManualExample(tmp);
			});
		}
	}, [open, currentExampleState]);

	useEffect(() => {
		setCsvReport(createCSVTemplate(allColumns));

		if (!allColumns.length) return;
		const categories = [];
		const getCategoryValues = async (id, nodeType) => {
			setIsLocalLoading(true);
			const nodeTypeForRequest =
				nodeType === 'metadata' ? nodeType : `${nodeType}s`;
			const res = await requestFactory(
				'get',
				`/schema/${nodeTypeForRequest}/${id}/categories`,
				null
			);
			categories.push({ field: id, categories: res });
			setIsLocalLoading(false);
			return {
				id,
				res,
			};
		};

		allColumns.forEach((column) => {
			if (column.valueType === 'Category') {
				getCategoryValues(column.field, column.fieldType).then(() => {
					setManualElementCategories(categories);
				});
			}
		});
	}, [allColumns]);

	const handleClose = async () => {
		setCreationStep(0);
		setOpen(false);
		setFileToUpload(null);
		setManualExample({
			...manualExample,
			values: [],
		});
	};

	const handleAddExampleManually = () => {
		setCreationStep(1);
	};

	// const handleChangeCurrentRowStatus = (e) => {
	// 	setManualExample({ ...manualExample, labeling_status: e.target.value });
	// };

	// const handleChangeManualExampleDate = (value, name) => {
	// 	setManualExample({
	// 		...manualExample,
	// 		[name]: { fieldType: manualExample[name].fieldType, value },
	// 	});
	// };
	// const handleUploadImage = async ({
	// 	e,
	// 	usedFor,
	// 	fileType,
	// 	field,
	// 	dateValue,
	// 	valueType,
	// }) => {};

	useEffect(() => {
		if (Object.keys(tmpImage).length > 0)
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === tmpImage.element)
						return {
							element: tmpImage.element,
							value: tmpImage.value,
							thumbnailUrl: tmpImage.thumbnailUrl,
							tmpInfo: tmpImage.tmpInfo,
							isLoading: false,
						};
					return val;
				}),
			});
	}, [tmpImage]);

	const handleChangeManualExample = async ({
		e,
		usedFor,
		fileType,
		field,
		dateValue,
		valueType,
	}) => {
		let value = null;
		if (!dateValue) {
			value = e.target.value;
		}

		if (!dateValue && e.target.files && e.target.files[0]) {
			e.preventDefault();
			e.stopPropagation();

			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) return { ...val, isLoading: true };
					return val;
				}),
			});

			const file = e.target.files[0];

			const res = await dispatch(
				CREATE_TASK_FILE({
					taskId: currentTaskState.id,
					file,
					accessToken,
					dispatch,
					usedFor,
					fileType,
				})
			);

			if (res.payload) {
				const resFile = await dispatch(
					GET_EXAMPLE_FILE({
						taskId: currentTaskState.id,
						fileId: res.payload.id,
						accessToken,
						dispatch,
						thumbnail: fileType === 'image',
					})
				);

				setTmpImage({
					element: field,
					value: res.payload.id,
					thumbnailUrl: resFile.payload.download_url,
					tmpInfo: res.payload,
					isLoading: false,
				});
				dispatch(
					ADD_ALERT({ type: 'success', message: IMAGE_FILE_UPLOAD_SUCCESS })
				);
				setTimeout(() => {
					dispatch(REMOVE_ALERT(IMAGE_FILE_UPLOAD_SUCCESS));
				}, 2000);
			} else {
				dispatch(
					ADD_ALERT({ type: 'error', message: IMAGE_FILE_UPLOAD_ERROR })
				);
			}
		} else if (value === 'on' || value === 'off') {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) {
						return { element: field, value: 'checked' };
					}
					return val;
				}),
			});
		} else if (dateValue) {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) {
						return {
							element: field,
							value: getLocalDateTime(dateValue, userLocationState),
						};
					}
					return val;
				}),
			});
		} else {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field)
						return {
							element: field,
							value:
								valueType === 'integer' || valueType === 'float'
									? Number(value)
									: value,
						};
					return val;
				}),
			});
		}
	};

	const handleSaveCreate = async () => {
		if (creationStep === 0) {
			setCreationStep(1);
		}
		if (creationStep === 1) {
			setCreationStep(0);
			setOpen(false);
			setFileToUpload(null);

			// CREATE EXAMPLE MANUALLY

			const tmpValues = manualExample.values
				.filter((val) => {
					if (
						val.value === '' ||
						val.value === null ||
						val.value === undefined ||
						schemaState.outputs.some((output) => output.name === val.element)
					)
						return false;
					return val;
				})
				.map((val) => ({ element: val.element, value: val.value }));

			const tmpTargets = manualExample.values.filter((val) =>
				schemaState.outputs.some((output) => output.name === val.element)
			);

			await dispatch(
				CREATE_TEST({
					taskId: currentTaskState.id,
					newTest: {
						stage: manualExample.stage,
						batch: [
							{
								values: tmpValues,
								targets: tmpTargets,
							},
						],
					},
					testsState,
					accessToken,
					dispatch,
				})
			);

			setTimeout(() => {}, 1000);

			handleClose();
		}
	};

	const handleClickUploadFile = () => {
		hiddenFileInput.current.click();
	};

	const handleChangeUploadCSV = async (event) => {
		setIsLoadingFile(true);
		setFileToUpload(event.target.files[0]);
		const newTests = createValidatedTestingObjects(
			await parseCSVFile({
				file: event.target.files[0],
				allColumns,
				schemaState,
				dispatch,
				setIsLoadingFile,
				setFileToUpload,
			})
		);

		await dispatch(
			CREATE_TEST({
				taskId: currentTaskState.id,
				newTest: {
					stage: 'testing',
					batch: newTests,
				},
				testsState,
				accessToken,
				dispatch,
			})
		);
		setIsLoadingFile(false);
		handleClose();
	};

	const handleResetInputFile = (e) => {
		e.target.value = null;
	};

	const handleChangeCurrentRowRelease = (e) => {
		setManualExample({ ...manualExample, ai_model: e.target.value });
	};

	const handleOpenDeleteExampleModal = () => {
		setOpenDeleteExampleModal(true);
	};

	return (
		<StandardModal
			open={open}
			setOpen={setOpen}
			title="Create test:"
			content={
				<>
					{creationStep === 0 && (
						<Box className={classes.dialogContentContainer}>
							<Box className={classes.createTypeContainer}>
								<div className={classes.csvUploadColumn}>
									<button
										type="button"
										onClick={handleAddExampleManually}
										className={styles.createExampleButton}
									>
										Create manually
										<FontAwesomeIcon icon={faKeyboard} />
									</button>
								</div>
								<div className={classes.csvUploadColumn}>
									{isLoadingFile && (
										<button
											type="button"
											onClick={handleClickUploadFile}
											className={classes.createExampleButton}
										>
											Preparing file...
											<Loader size="L" local />
										</button>
									)}
									{!isLoadingFile && fileToUpload === null && (
										<>
											<button
												type="button"
												onClick={handleClickUploadFile}
												className={classes.createExampleButton}
											>
												Add CSV file
												<FontAwesomeIcon icon={faFileCsv} />
											</button>
											<input
												type="file"
												ref={hiddenFileInput}
												onChange={handleChangeUploadCSV}
												style={{ display: 'none' }}
											/>
										</>
									)}
									{!isLoadingFile && fileToUpload !== null && (
										<button
											type="button"
											className={classes.createExampleButton}
										>
											Selected file:
											{fileToUpload.name}
											<FontAwesomeIcon icon={faCloudArrowUp} />
										</button>
									)}
									{/* <input type="file"
                  className={classes.createExampleButton}
                  value={
                    <>
                      Add CSV file
                      <FontAwesomeIcon icon={faFileCsv} />
                    </>
                  }
                /> */}
									{!isLoadingFile && fileToUpload === null && csvReport && (
										<div className={styles.Export_btn}>
											<StandardButton
												type="textButton"
												value={
													<CSVLink
														// data={csvReport.data}
														filename={csvReport.filename}
														headers={csvReport.headers}
														data={[]}
													>
														Export CSV template
													</CSVLink>
												}
											/>
										</div>
									)}
								</div>
							</Box>
						</Box>
					)}

					{creationStep === 1 && (
						<Box className={classes.exampleColumnsContainer}>
							{allColumns.map(
								(column) =>
									column.field !== 'edit' &&
									column.field !== 'status' &&
									column.fieldType !== 'target' && (
										<Box key={column.id} className={classes.exampleColumnsRow}>
											<Box className={classes.nameColumn}>
												{column.fieldType &&
													column.fieldType !== 'status' &&
													column.valueType &&
													elementTypeToIconMap[column.valueType](
														column.fieldType
													)}
												<div className={styles.create_data_labels}>
													{column.fieldType !== 'status' && column.name}
												</div>
											</Box>
											<Box className={classes.inputColumn}>
												{
													// INTEGER TYPE
													column.valueType === 'integer' && (
														<Input
															className={styles.common_input_type}
															type="number"
															value={
																manualExample.values.find(
																	(element) => element.element === column.field
																)?.value || ''
															}
															name={column.field}
															onChange={(e) =>
																handleChangeManualExample({
																	e,
																	field: column.field,
																	valueType: column.valueType,
																})
															}
														/>
													)
												}
												{
													// BOOLEAN TYPE
													column.valueType === 'boolean' && (
														<FormControlLabel
															control={
																<Switch
																	checked={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value
																	}
																	name={column.field}
																	onChange={(e) =>
																		handleChangeManualExample({
																			e,
																			field: column.field,
																		})
																	}
																/>
															}
															label={
																manualExample.values.find(
																	(element) => element.element === column.field
																)?.value
																	? 'True'
																	: 'False'
															}
														/>
													)
												}
												{
													// DATETIME TYPE
													column.valueType === 'datetime' && (
														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<DateTimePicker
																className={styles.date_pick_input_type}
																value={
																	manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(dateValue) =>
																	handleChangeManualExample({
																		dateValue,
																		field: column.field,
																	})
																}
																renderInput={(params) => (
																	<TextField {...params} helperText={null} />
																)}
																inputFormat="YYYY/MM/DD hh:mm A"
															/>
														</LocalizationProvider>
													)
												}
												{
													// FLOAT TYPE
													column.valueType === 'float' && (
														<Input
															className={styles.common_input_type}
															value={
																manualExample.values.find(
																	(element) => element.element === column.field
																)?.value || ''
															}
															name={column.field}
															onChange={(e) =>
																handleChangeManualExample({
																	e,
																	field: column.field,
																	valueType: column.valueType,
																})
															}
															type="number"
														/>
													)
												}
												{
													// TEXT TYPE
													column.valueType === 'text' && (
														<TextField
															multiline
															rows={5}
															maxRows={5}
															inputProps={{ maxLength: 32000 }}
															id={column.field}
															className={styles.common_input_type}
															value={
																manualExample.values.find(
																	(element) => element.element === column.field
																)?.value || ''
															}
															name={column.field}
															onChange={(e) =>
																handleChangeManualExample({
																	e,
																	field: column.field,
																})
															}
														/>
													)
												}
												{
													// CATEGORY TYPE
													column.valueType === 'category' && (
														<Select
															id="Category"
															className={classes.typeCategory}
															value={
																manualExample.values.find(
																	(element) => element.element === column.field
																)?.value || ''
															}
															name={column.field}
															onChange={(e) =>
																handleChangeManualExample({
																	e,
																	field: column.field,
																})
															}
														>
															<MenuItem value={null}>None</MenuItem>
															{categoriesState &&
																categoriesState[
																	column.fieldType !== 'metadata'
																		? `${column.fieldType}s`
																		: column.fieldType
																] &&
																categoriesState[
																	column.fieldType !== 'metadata'
																		? `${column.fieldType}s`
																		: column.fieldType
																].length > 0 &&
																categoriesState[
																	column.fieldType !== 'metadata'
																		? `${column.fieldType}s`
																		: column.fieldType
																]
																	.find((c) => c.id === column.id)
																	?.categories?.map((category) => (
																		<MenuItem
																			value={category.name}
																			key={category.id}
																		>
																			<em>
																				{category.display_name || category.name}
																			</em>
																		</MenuItem>
																	))}
														</Select>
													)
												}
												{
													// GENERIC FILE TYPE
													column.valueType === 'generic_file' && (
														<div className={classes.uploadContainer}>
															<StandardButton value="Upload video" />
															<div className={styles.data_preview}>
																Generic file preview
															</div>
															<div className={styles.data_preview}>
																Generic file name
															</div>
														</div>
													)
												}
												{
													// DOCUMENT FILE TYPE
													column.valueType === 'document_file' && (
														<div className={classes.uploadContainer}>
															{manualExample.values.find(
																(element) => element.element === column.field
															)?.isLoading ? (
																<StandardButton
																	value={<Loader size="S" local />}
																	type="uploadFile"
																/>
															) : (
																<StandardButton
																	accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || 'Upload document'
																	}
																	type="uploadFile"
																	handleChange={(e) =>
																		handleChangeManualExample({
																			e,
																			usedFor: column.fieldType,
																			fileType: 'document',
																			field: column.field,
																		})
																	}
																	id={column.field}
																	name={column.field}
																	handleClick={handleResetInputFile}
																/>
															)}
															<div className={styles.data_preview}>
																Can't load document preview
															</div>
														</div>
													)
												}
												{
													// IMAGE TYPE
													column.valueType === 'image_file' && (
														<div className={classes.uploadContainer}>
															{manualExample.values.find(
																(element) => element.element === column.field
															)?.isLoading ? (
																<StandardButton
																	value={<Loader size="S" local />}
																	type="uploadFile"
																/>
															) : (
																<StandardButton
																	accept="image/.jpg, image/.png"
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || 'Upload image'
																	}
																	type="uploadFile"
																	handleChange={(e) =>
																		handleChangeManualExample({
																			e,
																			usedFor: column.fieldType,
																			fileType: 'image',
																			field: column.field,
																		})
																	}
																	id={column.field}
																	name={column.field}
																	handleClick={handleResetInputFile}
																/>
															)}
															<div className={styles.data_preview}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.thumbnailUrl ? (
																	<img
																		alt={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value
																		}
																		src={
																			// manualExample.values.find(
																			// 	(element) =>
																			// 		element.element === column.field
																			// )?.value &&
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.thumbnailUrl !== '' &&
																			// ? () =>
																			// 		getThumbnailImage(
																			// 			manualExample.values.find(
																			// 				(element) =>
																			// 					element.element ===
																			// 					column.field
																			// 			)?.value
																			// 		)
																			// :
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.thumbnailUrl
																		}
																		className={classes.imagePreview}
																	/>
																) : (
																	`Can't load image preview`
																)}
															</div>
														</div>
													)
												}
												{
													// VIDEO TYPE
													column.valueType === 'video_file' && (
														<div className={classes.uploadContainer}>
															{manualExample.values.find(
																(element) => element.element === column.field
															)?.isLoading ? (
																<StandardButton
																	value={<Loader size="S" local />}
																	type="uploadFile"
																/>
															) : (
																<StandardButton
																	accept="video/*"
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || 'Upload video'
																	}
																	type="uploadFile"
																	handleChange={(e) =>
																		handleChangeManualExample({
																			e,
																			usedFor: column.fieldType,
																			fileType: 'video',
																			field: column.field,
																		})
																	}
																	id={column.field}
																	name={column.field}
																	handleClick={handleResetInputFile}
																/>
															)}
															<div className={styles.data_preview}>
																Can't load video preview
															</div>
														</div>
													)
												}
												{
													// AUDIO TYPE
													column.valueType === 'audio_file' && (
														<div className={classes.uploadContainer}>
															{manualExample.values.find(
																(element) => element.element === column.field
															)?.isLoading ? (
																<StandardButton
																	value={<Loader size="S" local />}
																	type="uploadFile"
																/>
															) : (
																<StandardButton
																	accept="audio/*"
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || 'Upload audio'
																	}
																	type="uploadFile"
																	handleChange={(e) =>
																		handleChangeManualExample({
																			e,
																			usedFor: column.fieldType,
																			fileType: 'audio',
																			field: column.field,
																		})
																	}
																	id={column.field}
																	name={column.field}
																	handleClick={handleResetInputFile}
																/>
															)}
															<div className={styles.data_preview}>
																Can't load audio preview
															</div>
														</div>
													)
												}
												{
													// SHAPE TYPE
													column.valueType === 'shape' && 'shape'
												}
											</Box>
										</Box>
									)
							)}
						</Box>
					)}
				</>
			}
			actions={
				<>
					{creationStep === 1 && (
						<StandardButton
							loading={manualExample.values.some(
								(element) => element.isLoading
							)}
							value="Create test"
							handleClick={handleSaveCreate}
						/>
					)}
					<StandardButton value="Close" handleClick={handleClose} close />
				</>
			}
		/>
	);
};

CreateOrUpdateAITestingModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	allColumns: PropTypes.array,
	schemaState: PropTypes.object,
	type: PropTypes.string,
	step: PropTypes.number,
	currentRowId: PropTypes.string,
	setOpenDeleteExampleModal: PropTypes.func,
};
