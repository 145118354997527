import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import requestFactory from '../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from './alerts.slice';
import { TEST_CREATED } from '../AlertsList/testsAlerts';

// GET

export const GET_PREDICTIONS = createAsyncThunk(
	'user/GET_PREDICTIONS',
	async (props) => {
		console.log('user/GET_PREDICTIONS');
		const tmpProps = [];
		let isPage = false;
		let isPerPage = false;
		let isAIModel = false;
		const { dispatch, taskId, accessToken } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				if (prop === 'page') isPage = true;
				if (prop === 'per_page') isPerPage = true;
				if (prop === 'ai_model') isAIModel = true;
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';
		// Set the default options
		if (isPage)
			query += `page=${tmpProps.find((prop) => prop['page'])['page']}`;
		else query += 'page=1';
		if (isPerPage)
			query += `&per_page=${
				tmpProps.find((prop) => prop['per_page'])['per_page']
			}`;
		if (isAIModel)
			query += `&ai_model=${
				tmpProps.find((prop) => prop['ai_model'])['ai_model']
			}`;
		else query += '&per_page=25';
		query += '&total_count=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query'
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/prediction-logs${query}`,
			accessToken,
			dispatch,
		});

		return res;
	}
);

// POST

export const CREATE_PREDICTION = createAsyncThunk(
	'user/CREATE_PREDICTION',
	async ({
		taskId,
		newPrediction,
		accessToken,
		dispatch,
		formatedPrediction,
	}) => {
		console.log('user/CREATE_PREDICTION');

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/predict`,
			accessToken,
			dispatch,
			data: newPrediction,
		});

		if (res) {
			dispatch(ADD_ALERT({ type: 'success', message: TEST_CREATED }));
			setTimeout(() => {
				dispatch(REMOVE_ALERT(TEST_CREATED));
			}, 2000);
		}

		return {
			outputs: res.predictions.outputs,
			...formatedPrediction,
		};
	}
);

export const predictionSlice = createSlice({
	name: 'predictions',
	initialState: {
		isLoading: false,
		currentPrediction: {},
		predictions: [],
		totalPredictions: 0,
	},
	reducers: {
		SET_CURRENT_PREDICTION: (state, { payload }) => {
			console.log('SET_CURRENT_PREDICTION');
			state.currentPrediction = payload;
		},
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_PREDICTIONS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_PREDICTIONS.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.predictions = payload.data;
			state.totalPredictions = payload.total_count;
		});
		builder.addCase(GET_PREDICTIONS.rejected, (state) => {
			state.isLoading = false;
		});
		// POST

		builder.addCase(CREATE_PREDICTION.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_PREDICTION.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			console.log(payload);
			state.predictions = [...state.predictions, payload];
		});
		builder.addCase(CREATE_PREDICTION.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const { SET_CURRENT_PREDICTION } = predictionSlice.actions;

export default predictionSlice.reducer;
