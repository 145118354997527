import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Styles
import useStyles from './styles';

import styles from './styles.module.css';
import { CustomTooltip } from '../../CustomTooltip';

function StandardButton({
	type,
	handleClick,
	handleChange,
	value,
	linkTo = null,
	close = null,
	loading,
	icon,
	id,
	name,
	accept,
	disabled,
	tooltip = null,
}) {
	const classes = useStyles();

	const handleLinkTo = () => {
		if (linkTo) window.location.replace(`https://${linkTo}`);
	};

	if (loading) {
		return (
			<LoadingButton
				loading
				variant="outlined"
				className={classes.loadingButton}
			>
				{value}
			</LoadingButton>
		);
	}

	if (type === 'uploadFile') {
		if (loading) {
			return (
				<LoadingButton
					loading
					variant="outlined"
					className={classes.loadingButton}
				>
					{value}
				</LoadingButton>
			);
		}
		return (
			<Button
				variant="contained"
				component="label"
				className={classes.button}
				startIcon={icon}
				// loading
			>
				{value}
				<input
					hidden
					accept={accept}
					multiple
					type="file"
					onClick={handleClick}
					onChange={handleChange}
					id={id}
					name={name}
				/>
			</Button>
		);
	}

	if (type === 'danger') {
		return (
			<Button
				className={styles.danger_btn}
				onClick={() => {
					if (linkTo) handleLinkTo();
					if (handleClick) handleClick();
				}}
				variant="contained"
				color="error"
				size="small"
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'textButton') {
		return (
			<Button
				type="button"
				className={classes.textButton}
				onClick={() => {
					if (linkTo) handleLinkTo();
					if (handleClick) handleClick();
				}}
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'disabled') {
		if (tooltip) {
			return (
				<Tooltip title={tooltip}>
					<Button
						className={`${classes.button} ${classes.closeButton}`}
						variant="contained"
						disabled
						startIcon={icon}
					>
						{value}
					</Button>
				</Tooltip>
			);
		}
		return (
			<Button
				className={`${classes.button} ${classes.closeButton}`}
				variant="contained"
				disabled
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'filled') {
		return (
			<Button
				className={classes.button}
				onClick={() => {
					if (handleClick) handleClick();
				}}
				variant="contained"
				disabled={disabled || false}
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'with-icon') {
		return (
			<Button
				className={classes.withIcon}
				onClick={() => {
					if (handleClick) handleClick();
				}}
				variant="contained"
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'submit') {
		return (
			<Button
				className={classes.button}
				variant="contained"
				size="small"
				type="submit"
				onClick={() => {
					if (handleClick) handleClick();
				}}
				loading
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	return (
		<Button
			className={`${classes.button} ${close !== null && classes.closeButton}`}
			onClick={() => {
				if (linkTo) handleLinkTo();
				if (handleClick) handleClick();
			}}
			variant="contained"
			size="small"
			startIcon={icon}
		>
			{value}
		</Button>
	);
}

StandardButton.propTypes = {
	type: PropTypes.string,
	handleClick: PropTypes.func,
	linkTo: PropTypes.string,
	value: PropTypes.any,
	close: PropTypes.bool,
	loading: PropTypes.bool,
	icon: PropTypes.element,
	handleChange: PropTypes.func,
	id: PropTypes.string,
	name: PropTypes.string,
	accept: PropTypes.string,
	disabled: PropTypes.bool,
	tooltip: PropTypes.any,
};

export default StandardButton;
