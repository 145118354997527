import { Box, Grid, Input, TextField, TextareaAutosize } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { UPDATE_EXAMPLE } from '../../../../../redux/examples.slice';

export const TextEditor = ({
	classes,
	currentElement,
	currentCellId,
	currentRowId,
	currentElementValueType,
	currentValue,
	setCurrentValue,
	name = null,
	disabled = false,
}) => {
	const dispatch = useDispatch();

	console.log(disabled);

	useEffect(() => {
		if (currentElement) setCurrentValue(currentElement.value);
	}, [currentElement || currentValue]);

	const handleChange = (e) => {
		if (!name) setCurrentValue(e.target.value);
		else setCurrentValue(name, e.target.value);
	};

	if (
		(currentElementValueType === 'float' ||
			currentElementValueType === 'integer') &&
		currentValue !== ''
	) {
		return (
			<Input
				disabled={disabled}
				value={currentValue}
				onChange={handleChange}
				type="number"
			/>
		);
	}

	return (
		<TextField
			multiline
			rows={5}
			maxRows={10}
			inputProps={{ maxLength: 32000 }}
			value={currentValue}
			onChange={handleChange}
			disabled={disabled}
		/>
	);
};

TextEditor.propTypes = {
	classes: PropTypes.object,
	currentElement: PropTypes.object,
	currentCellId: PropTypes.string,
	currentRowId: PropTypes.string,
	currentElementValueType: PropTypes.string,
	currentValue: PropTypes.any,
	setCurrentValue: PropTypes.func,
	name: PropTypes.string,
	disabled: PropTypes.bool,
};
