import React, { memo } from 'react';
import { Handle } from 'reactflow';
// import { Handle } from 'react-flow-renderer';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * CustomMetadataNode is a HOC that defines a custom node for the react-flow-renderer library. It is used to render a schema
 * metadata node, which represents a additional information for the inputs of ENAIA.
 *
 * @param {Object} data
 * @param {boolean} isConnectable
 * @return {JSX.Element} The rendered node
 *
 * @component
 * @category Flow
 *
 * @author Enrique Hernández Calabrés
 */
function CustomMetadataNode({ data, isConnectable }) {
	return (
		<>
			<Typography variant="body2">{data.label}</Typography>
			<Handle
				id="nodeOutput"
				type="source"
				position="top"
				isConnectable={isConnectable}
			/>
		</>
	);
}

export default memo(CustomMetadataNode);

CustomMetadataNode.propTypes = {
	data: PropTypes.any,
	isConnectable: PropTypes.any,
};
