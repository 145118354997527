import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Auth
// import { useAuth0 } from '@auth0/auth0-react';
import { useNeuAuth } from '@neuraptic/neuauth-react';

// Components
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// Styles
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../../Components/Shared/Copyright';
import { measures } from '../../consts/sizes';

// Images
// import { ReactComponent as Logo } from '../../assets/media/img/logo/SVG/ENAIA-Logo.svg';
import Logo from '../../assets/media/img/logo/enaia_imagotipoAPP.png';
import { APP_IS_LOADING } from '../../redux/loaders.slice';

const theme = createTheme();

function SignIn() {
	const dispatch = useDispatch();
	const { loginWithRedirect } = useNeuAuth();

	useEffect(() => {
		dispatch(APP_IS_LOADING(false));
	}, []);

	const handleLogin = async () => {
		loginWithRedirect();
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid
				container
				component="main"
				sx={{
					px: { xs: 1, sm: 4, md: 8, lg: 12 },
					py: { xs: 1, sm: 4, md: 8, lg: 24 },
					minHeight: '90vh',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: (t) =>
						t.palette.mode === 'light'
							? t.palette.grey[50]
							: t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				<CssBaseline />
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Grid
						item
						sx={{
							display: 'flex',
							alignItems: 'center',
							minWidth: '200px',
						}}
					>
						<img src={Logo} alt="" style={{ height: '15vh' }} />
						<div
							style={{
								marginLeft: '6px',
								display: 'flex',
								flexDirection: 'column',
								lineHeight: '3.5rem',
								fontFamily: 'Montserrat-Regular',
							}}
						>
							<div style={{ fontSize: '6rem' }}>DOJO</div>
						</div>
						{/* <Logo style={{ height: '10vh' }} /> */}
					</Grid>
					{/* <Typography component="h1" variant="h4" display="flex">
						Start building with your free plan
					</Typography> */}
				</Grid>
				<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						sx={{
							borderRadius: '1rem',
							py: 3,
							px: 3,
							width: {
								xs: '100%',
								sm: '60%',
								md: '50%',
								lg: '30%',
							},
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
						component={Paper}
						elevation={measures.cardItemElevation}
						square
					>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<Box
							component="form"
							noValidate
							onSubmit={handleLogin}
							sx={{ mt: 1 }}
						>
							{/* {error && <h1 style={{ color: 'red' }}>Error...</h1>} */}
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
							>
								{/* {isLoading ? <CircularProgress /> : 'Sign In'} */}
								Sign In
							</Button>
							<Copyright sx={{ mt: 5 }} />
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
}

export default SignIn;
