import { ADD_ALERT } from '../redux/alerts.slice';

const errorStatus = [400, 401, 402, 403, 404, 500];

// REQUEST FACTORY FUNCTION
const requestFactory = async ({ type, url, accessToken, data, dispatch }) => {
	// let tries = 0;
	// const totalTries = 2;

	let res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
		method: type,
		headers: {
			Authorization: `Bearer ${accessToken}`,
			'content-type': 'application/json',
			cors: 'no-cors',
		},
		body: data && JSON.stringify(data),
	});

	const json = await res.json();

	if (errorStatus.includes(res.status) && type === 'GET') {
		res = await fetch(`${process.env.REACT_APP_ENAIA_API_URL}${url}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'content-type': 'application/json',
				cors: 'no-cors',
			},
			body: data && JSON.stringify(data),
		});
	}

	if (errorStatus.includes(res.status)) {
		if (json.error) {
			dispatch(ADD_ALERT({ type: 'error', message: json.error.message }));
		} else if (json.errors) {
			Object.keys(json.errors.json).forEach((error) => {
				dispatch(
					ADD_ALERT({ type: 'error', message: json.errors.json[error][0] })
				);
			});
		} else
			dispatch(
				ADD_ALERT({
					type: 'error',
					message:
						'An unexpected error has occurred. Contact contact@neuraptic.ai for support.',
				})
			);
		return;
	}

	if (res) {
		return json;
	}

	return undefined;
};

export default requestFactory;
