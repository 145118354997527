import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Box } from '@mui/system';

// Styles
import { useStyles } from './styles';
import { addObjectSelectionFromShapeChips } from '../../Editors/ImageFileEditor.services';

const ChipShapesList = ({
	currentCellId,
	selectedShape,
	setSelectedShape,
	editor,
}) => {
	const classes = useStyles();

	const { currentShapes: currentShapesState } = useSelector(
		(state) => state.examples
	);

	useEffect(() => {
		addObjectSelectionFromShapeChips({
			editor,
			selectedShape,
		});
	}, [selectedShape]);

	const handleSelectShape = (e) => {
		setSelectedShape(e.target.id);
	};

	return (
		<>
			<h3 style={{ marginTop: 18, marginBottom: -12 }}>Shapes list:</h3>
			{currentShapesState && currentShapesState.length > 0 && (
				<Box className={classes.shapesChipsContainer}>
					{currentShapesState.map((shape) => {
						if (shape.element === currentCellId)
							return (
								<button
									key={uuidv4()}
									type="button"
									id={shape.id}
									className={`${classes.shapeChip} ${
										shape.id === selectedShape && classes.selectedShape
									}`}
									onClick={handleSelectShape}
								>
									{shape.id}
								</button>
							);
						return false;
					})}
				</Box>
			)}
		</>
	);
};

export default ChipShapesList;

ChipShapesList.propTypes = {
	currentCellId: PropTypes.string,
	selectedShape: PropTypes.string,
	setSelectedShape: PropTypes.func,
	editor: PropTypes.object,
};
