import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const getLocalDateTime = (date, userLocationState) =>
	formatInTimeZone(date, userLocationState, 'yyyy-MM-dd HH:mm');

export const getNewsDate = (input) => {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const dateArray = format(input, 'P').split('/');

	if (typeof input === 'object')
		return `${monthNames[input.getMonth()].toUpperCase()} ${dateArray[1]}, ${
			dateArray[2]
		}`;

	return null;
};
