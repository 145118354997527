import React, { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import StandardButton from '../../Shared/Buttons/StandardButton';

// Styles
import useStyles from './styles';

// Consts
import { colors } from '../../../consts/colors';
import { measures } from '../../../consts/sizes';

// Requests
import requestFactory from '../../../services/request.factory';

const theme = createTheme();

function Navigation() {
	const location = useLocation();
	const classes = useStyles();
	const dispatch = useDispatch();

	const navigationArray = location.pathname.split('/').slice(1);

	const { accessToken } = useSelector((state) => state.user);
	const { tasks: tasksState } = useSelector((state) => state.tasks);
	const { info: organizationInfoState } = useSelector(
		(state) => state.organization
	);
	const [downloadIsLoading, setDownloadIsLoading] = useState(false);

	const tasksIds = tasksState.map((task) => task.uuid);

	const getLinkUrl = (element) => {
		const index = navigationArray.indexOf(element);
		let result = '';
		for (let i = 0; i <= index; i += 1) {
			result += `/${navigationArray[i]}`;
		}
		return result;
	};

	const downloadEnaia = async () => {
		setDownloadIsLoading(true);
		const res = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationInfoState.uuid}/edge-api`,
			accessToken,
			dispatch,
		});

		setDownloadIsLoading(false);
		if (res.download_url) {
			window.open(res.download_url, '_blank');
		}
	};

	if (tasksIds.length > 0)
		return (
			<ThemeProvider theme={theme}>
				<Grid
					container
					component="main"
					id="top-menu"
					className={classes.mainContainer}
					px={{
						xs: 2,
						sm: 4,
						md: measures.mdGeneralMargin,
						lg: measures.lgGeneralMargin,
					}}
					sx={{
						height: { xs: '100px', sm: '48px' },
						mb: '12px',
						alignItems: 'center',
					}}
				>
					<Grid
						item
						xs={12}
						sm={9}
						md={10}
						sx={{
							height: '24px',
							display: 'flex',
							alignItems: 'flex-center',
							flexWrap: 'wrap',
						}}
					>
						{navigationArray.includes('dashboard') ? (
							navigationArray.map((element, index) => {
								if (element !== '') {
									return (
										<div
											className={classes.navContainer}
											style={{
												zIndex: `${navigationArray.length - 1} !important`,
											}}
											key={uuidv4()}
										>
											<Link
												style={
													Number(index) === Number(navigationArray.length - 1)
														? {
																color: colors.blue,
																fontWeigth: 'bold',
																backgroundColor: colors.ligthBlue,
																zIndex: navigationArray.length - `${index}1`,
														  }
														: { zIndex: navigationArray.length - `${index}1` }
												}
												to={getLinkUrl(element)}
												className={classes.links}
											>
												{element.charAt(0).toUpperCase() + element.slice(1)}
											</Link>
											<div
												style={
													Number(index) === Number(navigationArray.length - 1)
														? {
																borderLeftColor: colors.ligthBlue,
																zIndex: navigationArray.length - `${index}2`,
														  }
														: { zIndex: navigationArray.length - `${index}2` }
												}
												className={classes.arrowRight}
											/>
											<div
												style={{ zIndex: navigationArray.length - `${index}3` }}
												className={classes.arrowRightSecondary}
											/>
										</div>
									);
								}
								return null;
							})
						) : (
							<>
								<div
									className={classes.navContainerFirstElement}
									key={uuidv4()}
								>
									<Link
										key={uuidv4()}
										to="/dashboard"
										className={classes.linksFirstElement}
										style={{ zIndex: navigationArray.length + 2 }}
									>
										Dashboard
									</Link>
									<div
										style={{ zIndex: navigationArray.length + 1 }}
										className={classes.arrowRightFirstElement}
									/>
									<div
										style={{ zIndex: navigationArray.length }}
										className={classes.arrowRightSecondaryFirstElement}
									/>
								</div>
								{navigationArray.map((element, index) => {
									if (element !== '') {
										return (
											<div
												className={classes.navContainer}
												style={{
													zIndex: `${navigationArray.length - 1} !important`,
												}}
												key={uuidv4()}
											>
												<Link
													style={
														Number(index) === Number(navigationArray.length - 1)
															? {
																	color: colors.blue,
																	fontWeigth: 'bold',
																	backgroundColor: colors.ligthBlue,
																	zIndex: navigationArray.length - `${index}1`,
															  }
															: { zIndex: navigationArray.length - `${index}1` }
													}
													to={getLinkUrl(element)}
													className={classes.links}
												>
													{tasksIds.includes(element)
														? tasksState.find((task) => task.uuid === element)
																.name
														: element.charAt(0).toUpperCase() +
														  element.slice(1).replace(/-/g, ' ')}
													{/* {element.charAt(0).toUpperCase() +
													element.slice(1).replace(/-/g, ' ')} */}
												</Link>
												<div
													style={
														Number(index) === Number(navigationArray.length - 1)
															? {
																	borderLeftColor: colors.ligthBlue,
																	zIndex: navigationArray.length - `${index}2`,
															  }
															: { zIndex: navigationArray.length - `${index}2` }
													}
													className={classes.arrowRight}
												/>
												<div
													style={{
														zIndex: navigationArray.length - `${index}3`,
													}}
													className={classes.arrowRightSecondary}
												/>
											</div>
										);
									}
									return null;
								})}
							</>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						sm={3}
						md={2}
						sx={{
							height: '24px',
							display: 'flex',
							alignItems: 'center',
							justifySelf: 'flex-end !important',
						}}
					>
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'rigth',
							}}
						>
							{/* <StandardButton value="Deploy ENAIA" /> */}
							<StandardButton
								// type="disabled"
								value="Deploy ENAIA"
								handleClick={downloadEnaia}
								loading={downloadIsLoading}
							/>
						</div>
					</Grid>
				</Grid>
			</ThemeProvider>
		);
}

export default Navigation;
