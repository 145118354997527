import { makeStyles } from '@mui/styles';
import { colors } from '../../../../../../consts/colors';
// import { colors } from '../../../../../../consts/colors';

export const useStyles = makeStyles({
	shapesChipsContainer: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: '24px',
		height: '150px',
		alignContent: 'flex-start',
		overflowX: 'auto',
	},

	shapeChip: {
		backgroundColor: colors.ligthGray,
		maxWidth: '30%',
		padding: '6px 12px',
		borderRadius: '12px',
		margin: '6px',
		cursor: 'pointer',
		border: 'none',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		height: '25px',
	},

	selectedShape: {
		backgroundColor: colors.lightBlueButton,
	},
});
