import React, { memo } from 'react';
import { Handle } from 'reactflow';
// import { Handle } from 'react-flow-renderer';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * CustomOutputNode is a HOC that defines a custom node for the react-flow-renderer library. It is used to render a schema
 * output node, which represents the outputs that ENAIA should provide.
 *
 * @param {Object} data
 * @param {boolean} isConnectable
 * @return {JSX.Element} The rendered node
 *
 * @component
 * @category Flow
 *
 * @author Enrique Hernández Calabrés
 */
function CustomOutputNode({ data, isConnectable }) {
	return (
		<>
			<Handle
				id="nodeInput"
				type="target"
				position="left"
				isConnectable={isConnectable}
			/>
			<Typography variant="body2">{data.label}</Typography>
		</>
	);
}

export default memo(CustomOutputNode);

CustomOutputNode.propTypes = {
	data: PropTypes.any,
	isConnectable: PropTypes.any,
};
