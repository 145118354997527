import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';

// Styles
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Components
import { Loader } from '../../../../Components/Shared/Loader';

// Redux
import requestFactory from '../../../../services/request.factory';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url
).toString();

export const createCell = {
	integer: ({ classes, cellValue }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>{parseInt(cellValue)}</Typography>;
	},
	boolean: ({ classes, cellValue }) => {
		const { rowCell } = classes;
		return (
			<Typography className={rowCell}>
				{cellValue === '' || cellValue === 'false' ? 'False' : 'True'}
			</Typography>
		);
	},
	datetime: ({ classes, cellValue }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	float: ({ classes, cellValue }) => {
		const { rowCell } = classes;
		return parseFloat(cellValue) || 0.0;
	},
	text: ({ classes, cellValue }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	category: ({ classes, cellValue, categoriesState, valueType, params }) => {
		const { rowCell } = classes;

		if (!cellValue) return <Typography> </Typography>;

		if (
			categoriesState &&
			categoriesState[valueType] &&
			categoriesState[valueType].length > 0
		) {
			const tmpCats = categoriesState[valueType].find(
				(cat) => cat.name === params.field
			)?.categories;
			const tmpValue = tmpCats.find(
				(cat) => cat.display_name === cellValue || cat.name === cellValue
			);

			if (tmpValue && tmpValue.display_name)
				return (
					<Typography className={rowCell}>{tmpValue.display_name}</Typography>
				);
			if (tmpValue && tmpValue.name)
				return (
					<Typography className={rowCell}>
						{tmpValue.display_name || tmpValue.name}
					</Typography>
				);
		}
		return <Typography className={rowCell}>{cellValue}</Typography>;
	},
	generic_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>GenericFile</Typography>;
	},
	document_file: ({ cellValue, documentsBufferState }) => {
		if (
			documentsBufferState &&
			documentsBufferState.length > 0 &&
			documentsBufferState.find((element) => element.elementId === cellValue)
		) {
			return (
				<Box
					sx={{
						mt: '20px',
						mb: '20px',
						display: 'flex',
						backgroundColor: 'white',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Document
						file={
							documentsBufferState.find(
								(element) => element.elementId === cellValue
							).url
						}
					>
						<Page
							renderMode="svg"
							renderTextLayer={false}
							renderAnnotationLayer={false}
							pageNumber={1}
						/>
					</Document>
				</Box>
			);
		}
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	image_file: ({ cellValue, cellName, imagesBufferState }) => {
		if (
			imagesBufferState &&
			imagesBufferState.length > 0 &&
			imagesBufferState.find((element) => element.elementId === cellValue)
		)
			return (
				<img
					src={
						imagesBufferState.find((element) => element.elementId === cellValue)
							.thumbnail
					}
					alt={cellName}
					style={{ width: '100%' }}
				/>
			);
		if (cellValue === undefined) {
			return '';
		}
		return <Loader size="S" />;
	},
	video_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>VideoFile</Typography>;
	},
	audio_file: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>AudioFile</Typography>;
	},
	shape: ({ classes }) => {
		const { rowCell } = classes;

		return <Typography className={rowCell}>Shape</Typography>;
	},
};

// export const createCell = {
// 	GenericFile: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>GenericFile</Typography>;
// 	},
// 	ImageFile: (classes, cellValue, cellName) => (
// 		<img src={cellValue} alt={cellName} style={{ width: '100%' }} />
// 	),
// 	Char: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	JSON: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	GenericIPAddress: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	URL: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	AudioFile: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>AudioFile</Typography>;
// 	},
// 	VideoFile: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>VideoFile</Typography>;
// 	},
// 	Text: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Email: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Integer: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Float: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	SmallInteger: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	BigInteger: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Binary: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Binary</Typography>;
// 	},
// 	Slug: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Slug</Typography>;
// 	},
// 	Decimal: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	PositiveBigInteger: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	PositiveInteger: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	PositiveSmallInteger: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Boolean: (
// 		classes,
// 		cellValue,
// 		cellName,
// 		taskHost,
// 		currentRowId,
// 		examplesState
// 	) => {
// 		const { rowCell } = classes;
// 		// const dispatch = useDispatch()

// 		// const handleChange = (value) => {
// 		//   let tmpUpdate = {};
// 		//   const tmpExample = examplesState.find(
// 		//     (example) => example.id || example.element === currentRowId
// 		//   );

// 		//   if (tmpExample) {
// 		//     tmpUpdate = { version: tmpExample.version, inputs: {} };
// 		//   }

// 		//   dispatch(UPDATE_EXAMPLE({ taskHost, currentRowId, tmpUpdate }));
// 		// }

// 		// return <FormControlLabel control={<Switch checked={cellValue || false} />} />

// 		return (
// 			<Typography className={rowCell}>
// 				{cellValue === '' ? 'Flase' : 'True'}
// 			</Typography>
// 		);
// 	},
// 	Category: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Date: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Date</Typography>;
// 	},
// 	DateTime: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>{cellValue}</Typography>;
// 	},
// 	Time: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Time</Typography>;
// 	},
// 	Duration: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Duration</Typography>;
// 	},
// 	Shape: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>Shape</Typography>;
// 	},
// 	null: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>null</Typography>;
// 	},
// 	undefined: (classes, cellValue) => {
// 		const { rowCell } = classes;

// 		return <Typography className={rowCell}>undefined</Typography>;
// 	},
// };
